<template>
    <div class="delete-popup">
        <div class="popup-content">
            <div class="warning-icon">
                <img :src="deleteicon" alt="warning-icon" />
            </div>
            <h2>All of your recordings and notes will be deleted. </h2>
            <p>Please ensure that the above data is backed up locally, as deletion is irreversible. </p>
            <p>Are you sure you want to proceed with deletion?</p>
            <div class="buttons">
                <button class="delete-btn" @click="deleteAccount">Delete Account</button>
                <button class="cancel-btn" @click="cancelDeletion">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            deleteicon: require("@/assets/images/home/delete-icon.png")
        };

    },
    methods: {
        async deleteAccount() {
            // 触发删除账户逻辑
            try {
                const subid = this.$cookies.get("subid");
                const response = await this.$axios.post("/api/deleteuserinfo/", {
                    subid: subid,
                });
                const resdata = response.data;
                if (resdata.code == 200) {
                    this.logout();
                }

            } catch (error) {
                console.log(error);
            }
        },
        logout() {
            this.$cookies.remove("email");
            this.$cookies.remove("subid");
            this.$cookies.remove("userlogin");
            this.$cookies.remove("username");
            this.$router.push("/");
        },
        cancelDeletion() {
            // 取消删除操作
            this.$parent.isdeletepopup = false;
        }
    },

}
</script>
<style lang="less" scoped>
.delete-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    background-color: #00000070;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);


    .popup-content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #2e2b4b;
        padding: 2.5vw;
        text-align: center;
        color: white;

        h2 {
            color: #ff4c4c;
            font-size: 1.2vw;
            margin-bottom: 20px;
        }

        p {
            font-size: 1vw;
            line-height: 1.5;
            margin-bottom: 20px;
            padding: 0;
            margin: 0;
        }

        .warning-icon {
            margin-bottom: 15px;

            img {
                width: 7vw;
                height: 7vw;
            }
        }

        .buttons {
            margin-top: 1vw;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            button {
                padding: 0.8vw 0;
                flex: 1;
                margin: 0 1vw;
                font-size: 1.1vw;
                border-radius: 5px;
                cursor: pointer;
                border: none;
                transition: background-color 0.3s ease;

                &.delete-btn {
                    background-color: #ff4c4c;
                    color: white;

                    &:hover {
                        background-color: #ff1a1a;
                    }
                }

                &.cancel-btn {
                    background-color: white;
                    color: #333;

                    &:hover {
                        background-color: #e6e6e6;
                    }
                }
            }
        }
    }
}
</style>