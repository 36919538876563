<template>
  <div class="modal-subject" v-if="isVisible" @click.self="closeModal">
    <div class="modal">
      <h2>What type of class is this for?</h2>
      <p class="subtitle">This helps us create accurate notes for you.</p>
      <ul class="class-list">
        <li v-for="(item, index) in classes" :key="index">
          <div class="class-item" @click="selectClass(item)">
            <div class="icon"><img :src="item.icon" alt="" /></div>
            <div class="content">
              <h3>{{ item.title }}</h3>
              <p>{{ item.description }}</p>
            </div>
            <!-- <i class="arrow el-icon-arrow-right"></i> -->
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      classes: [
        {
          icon: require("../../../../assets/images/home/subjects/s1.png"),
          title: "Computer Science",
          description: "Data Structures, Algorithms, Operating Systems, etc.",
        },
        {
          icon: require("../../../../assets/images/home/subjects/s2.png"),
          title: "Economics",
          description:
            "Microeconomics, Macroeconomics, Behavioral Economics, etc.",
        },
        {
          icon: require("../../../../assets/images/home/subjects/s3.png"),
          title: "Biology",
          description: "Genetics, Ecology, Anatomy, Microbiology, etc.",
        },
        {
          icon: require("../../../../assets/images/home/subjects/s4.png"),
          title: "Chemistry",
          description:
            "Organic Chemistry, Inorganic Chemistry, Physical Chemistry, etc.",
        },
        {
          icon: require("../../../../assets/images/home/subjects/s5.png"),
          title: "Humanities",
          description:
            "Philosophy, Literature, Art History, Cultural Studies, etc.",
        },
        {
          icon: require("../../../../assets/images/home/subjects/s6.png"),
          title: "Math",
          description: "Calculus, Algebra, Geometry, Statistics, etc.",
        },
        {
          icon: require("../../../../assets/images/home/subjects/s7.png"),
          title: "Physics",
          description:
            "Classical Mechanics, Quantum Mechanics, Thermodynamics, etc.",
        },
        {
          icon: require("../../../../assets/images/home/subjects/s8.png"),
          title: "Other",
          description: "Any other topics not listed above",
        },
      ],
    };
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    selectClass(item) {
      this.$emit("selectclass", item);
      this.closeModal();
    },
  },
};
</script>

<style lang="less" scoped>
.modal-subject {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 5, 5, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  border-radius: 5px;
  background: rgb(56, 53, 74);
  color: white;
  padding: 15px 3vw;
  border-radius: 10px;
  // width: 80%;
  // max-width: 30vw;

  h2 {
    font-size: 1vw;
    font-family: Outfit;
    text-align: center;
  }

  .subtitle {
    color: #a0a0a0;
    text-align: center;
    font-family: Outfit;
    font-size: 0.98vw;
  }

  .class-list {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 10px;
    }

    .class-item {
      display: flex;
      align-items: center;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.1);
      padding: 10px 15px;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: rgb(57, 61, 77);
      }

      .icon {
        margin-right: 15px;
        font-size: 24px;
      }

      .content {
        flex-grow: 1;

        h3 {
          margin: 0;
          font-size: 0.88vw;
        }

        p {
          margin: 5px 0 0;
          color: #a0a0a0;
          font-size: 0.7vw;
        }
      }

      .arrow {
        color: #a0a0a0;
      }
    }
  }
}
</style>
