<template>
  <div class="pricing-container">
    <div class="free" v-if="false">
      <h1>Limited time free access to all features.</h1>
    </div>
    <div v-if="true">
      <h1>
        Get <span>Unlimited</span> audio recordings, notes, PDFs and flashcards
      </h1>
      <div class="billing-toggle" v-if="false">
        <button :class="{ active: billing === 'yearly' }" @click="billing = 'yearly'">
          Yearly
        </button>
        <button :class="{ active: billing === 'monthly' }" @click="billing = 'monthly'">
          Monthly
        </button>
      </div>
      <div class="plans">
        <div class="plan" v-for="plan in plans" :key="plan.name" :class="{ highlight: plan.name === 'Annual' }">
          <div class="badge" v-if="plan.name === 'Annual'">Save 60%</div>
          <h2 :class="{ h2red: plan.name !== 'Starter' }">{{ plan.name }}</h2>
          <div class="price">
            <span v-if="plan.price['dingyue'] != 'Free'">$</span>{{ plan.price["dingyue"] }}
            <span v-if="plan.price[billing] !== 'Free'">Per Month</span>
          </div>
          <div v-if="false" :class="{ billing: true, billinghl: plan.name === 'Unlimited' }">
            Billed {{ billing === "yearly" ? "Yearly" : "Monthly" }}
          </div>
          <ul>
            <li v-for="feature in plan.features" :key="feature">
              <span class="checkmark"></span> {{ feature }}
            </li>
          </ul>
          <button v-if="plan.price['dingyue'] == 'Free'" :class="{
            subscribe: true,
            subhighlight: plan.name === 'Unlimited',
          }" @click="gohomepage()">
            <span>Use Now</span>
          </button>
          <button v-else :class="{
            subscribe: true,
            subhighlight: plan.name === 'Unlimited',
          }" @click="subscribe(plan)">
            <span>Subscribe Now</span>
          </button>
          <!-- <button @click="createwechatpay(plan)"> wechatpay </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      billing: "yearly",
      plans: [
        {
          name: "Starter",
          price: { yearly: "Free", monthly: "Free", dingyue: "Free" },
          features: [
            "2 Hours of Lecture Time Per Month",
            "3 PDF Upload Per Month",
            "10 chat messages Per Month",
            "Unlimited flashcards Per Month",
            "Unlimited Quiz Questions Per Month",
          ],
        },
        {
          name: "Annual",
          interval: "year",
          price: { yearly: "3.99", monthly: "8.99", dingyue: "8.39" },
          features: [
            "Unlimited Hours Lecture Time Per Month",
            "Unlimited PDF Uploads Per Month",
            "Unlimited chat messages Per Month",
            "Unlimited flashcards Per Month",
            "Unlimited Quiz Questions Per Month",
          ],
        },
        {
          name: "Monthly",
          interval: "month",
          price: { yearly: "8.99", monthly: "12.99", dingyue: "19.99" },
          features: [
            "Unlimited Hours Lecture Time Per Month",
            "Unlimited PDF Uploads Per Month",
            "Unlimited chat messages Per Month",
            "Unlimited flashcards Per Month",
            "Unlimited Quiz Questions Per Month",
          ],
        },
      ],
    };
  },
  mounted() {
    this.getpaln();  
  },


  methods: {
    async getpaln() {
      try {
       const res = await this.$axios.post("/api/getpricedata/");
       const redata = res.data;
       this.plans = redata.data;
      } catch (error) {
        console.error(error);
      }
    },
    gohomepage() {
      this.$router.push("/home");
    },
    redirectToPayment() {
      // Stripe Payment Link
      const paymentLink = "https://buy.stripe.com/test_7sIdR2asD7398RG288";
      // 使用浏览器打开支付链接
      window.location.href = paymentLink;
    },
    async subscribe(plan) {
      console.log("Subscribing to", plan);
      // 解构获取价格单位、间隔时间和支付名称
      const { price: { dingyue: unitamount }, interval, name: payname } = plan;
      try {
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/createcheckoutsession/", {
          subid,
          unitamount,
          interval,
          payname,
        });

        const { url, code } = response.data;
        switch (code) {
          case 200:
            window.location.href = url;
            break;
          case 201:
            this.$message({
              message: "Please log in",
              type: "error",
            });
            this.$router.push("/login");
            break;
          case 301:
            this.$message({
              message: "You have already subscribed",
              type: "success",
            });
            this.$router.push("/home");
            break;
          default:
            this.$message({
              message: "Error creating checkout session",
              type: "error",
            });
        }
      } catch (error) {
        console.error("Error:", error);
        this.$message({
          message: "There was an error processing your request. Please try again.",
          type: "error",
        });
      }
    },
    async createwechatpay(plan) {
      console.log("Subscribing to", plan);
      const unitamount = plan.price["dingyue"];
      const interval = plan.interval;
      const payname = plan.name;
      try {
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/createwechatpaysession/", {
          subid: subid,
          unitamount: unitamount,
          interval: interval,
          payname: payname,
        });
        const { url, code } = response.data;
        if (code == 200) {
          window.location.href = url;
        } else if (code == 201) {
          this.$message({
            message: "Please log in",
            type: "error",
          });
          this.$router.push("/login");
        } else {
          this.$message({
            message: "Error creating checkout session",
            type: "error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        alert("There was an error processing your request. Please try again.");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.pricing-container {
  border: 1px solid #4e5164;
  border-left: none;
  border-right: none;
  color: white;
  font-family: Arial, sans-serif;
  padding: 50px 0px;
  text-align: center;

  .free {
    height: 500px;

    h1 {
      margin-top: 200px;
    }
  }

  h1 {
    font-size: 2vw;

    span {
      color: rgb(255, 87, 51);
    }
  }

  .billing-toggle {
    margin: 30px 0px;

    button {
      background-color: transparent;
      border: 1px solid #444;
      color: white;
      padding: 10px 20px;
      cursor: pointer;
      transition: all 0.3s;

      &.active {
        background-color: #7c3aed;
        border-color: #7c3aed;
      }

      &:first-child {
        border-radius: 20px 0 0 20px;
      }

      &:last-child {
        border-radius: 0 20px 20px 0;
      }
    }
  }

  .plans {
    display: flex;
    justify-content: center;

    .plan {
      padding: 0px 10px;
      flex: 1;
      text-align: left;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.1);
      margin: 2vw;

      &.highlight {
        box-shadow: 0px 0px 20px 4px rgb(255, 255, 255);
        transform: scale(1.05);
        overflow: hidden;

        .badge {
          position: absolute;
          top: -0.9vw;
          right: -2.5vw;
          background-color: #ff5733;
          /* 角标背景色 */
          color: white;
          /* 角标文本颜色 */
          padding: 2.5vw 0.1vw 0.7vw;
          width: 7vw;
          text-align: center;
          /* 使角标完全位于左上角 */
          font-size: 0.8vw;
          font-weight: 500;
          transform: rotate(45deg);
          /* 调整角标文字大小 */
        }
      }

      .subscribe {
        border-radius: 5px;
        border: none;
        background: rgb(108, 92, 231);
        color: white;
        cursor: pointer;
        font-size: 16px;
        font-weight: bold;
        padding: 0.6vw 2vw;
        width: 99%;
        margin: 1vw 0;
      }

      .subhighlight {
        background-color: white;
        border: none;
        color: #000;
      }

      h2 {
        font-size: 1.9vw;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
      }

      .h2red {
        color: #ff5733;
      }

      .price {
        font-size: 2vw;
        font-weight: bold;
        margin-bottom: 5px;
        text-align: center;
        padding: 10px 0px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          margin-left: 1vw;
          margin-right: 0.2vw;
          font-size: 1.5vw;
          font-weight: normal;
        }
      }

      .billing {
        color: #888;
        font-size: 14px;
        margin: 15px 0px;
        text-align: center;
      }

      .billinghl {
        color: white;
      }

      ul {
        // list-style-type: none;
        padding-left: 1.5vw;
        text-align: left;

        li {
          margin-bottom: 0.3vw;
          font-size: 14px;
          font-weight: bold;

          .checkmark {
            color: #ddd;
            margin-right: 5px;
            font-size: 0.9vw;
            font-weight: 900;
          }
        }
      }
    }
  }
}
</style>
