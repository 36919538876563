<template>
    <div class="lawful-interception-guide">
        <!-- <div ref="contentToDownload" class="markdown-content" v-if="notes.mk_notes" v-html="renderedNotes"></div> -->
        <div :class="{markmapbox: true}">
            <svg id="markmapsvg"></svg>
        </div>
    </div>
</template>

<script>
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import { Transformer } from "markmap-lib";
import { Markmap } from "markmap-view";
// import html2canvas from 'html2canvas'
// import { jsPDF } from 'jspdf'
export default {
    props: {
        noteobj: {
            type: Object,
            default: () => ({}),
        },
    },
    watch: {
        noteobj: {
            handler(newobject) {
                this.iscreating = this.noteobj.status;
                this.notes["iscreating"] = newobject.status;
                this.notes["taskcount"] = newobject.task_count;
                // this.notes["iscreating"] = 0;
                this.notes["mk_notes"] = newobject.note_text;
            },
            deep: true, // 添加深度监听
        },
    },
    computed: {
        renderedNotes() {
            if (!this.notes || typeof this.notes.mk_notes !== "string") {
                return ""; // 或者返回一个默认消息，如 "No notes available"
            }
            return this.md.render(this.notes.mk_notes);
        },
    },
    data() {
        return {

            md: new MarkdownIt({
                html: true,
                linkify: true,
                typographer: true,
                highlight: function (str, lang) {
                    if (lang && hljs.getLanguage(lang)) {
                        try {
                            return hljs.highlight(lang, str).value;
                        } catch (__) {
                            console.log("error", str, lang);
                        }
                    }
                    return ""; // 使用默认的转义
                },
            }),
            notes: {
                mk_notes: "",
                iscreating: 0,
            },
            mm: null,
            naotuloading: false,
        };
    },
    mounted() {
        this.notes["iscreating"] = this.noteobj.status;
        this.notes["mk_notes"] = this.noteobj.note_text;
        this.notes["type"] = this.noteobj.progress;
        this.notes["taskcount"] = this.noteobj.task_count;

        this.daoduTomarkmap(this.notes.mk_notes);
    },
    methods: {


        async daoduTomarkmap(markobj) {

            await this.rendernaotu(markobj); // 等待渲染完成

        },

        async rendernaotu(markstr) {
            if (this.mm) {
                // 如果脑图实例存在，则先销毁
                this.mm.destroy();

                // 创建 SVG 元素
                const svg = document.createElementNS(
                    "http://www.w3.org/2000/svg",
                    "svg"
                );
                svg.setAttribute("id", "markmapsvg");

                // 获取 markmapbox 容器元素
                const markmapbox = document.querySelector(".markmapbox");

                // 清空 markmapbox 容器元素中的内容
                markmapbox.innerHTML = "";

                // 将创建的 SVG 元素添加到 markmapbox 容器中
                markmapbox.appendChild(svg);
                //
            }

            const transformer = new Transformer();
            const { root } = await transformer.transform(markstr);
            const markmapsvg = await this.getHTMLAsPromise("markmapsvg");

            this.mm = await Markmap.create(markmapsvg);
            this.mm.setData(root);
            this.mm.fit();

            this.naotuloading = false;
        },
        getHTMLAsPromise(nameid) {
            return new Promise((resolve, reject) => {
                this.$nextTick(() => {
                    const svgElement = document.getElementById(nameid);
                    if (svgElement) {
                        resolve(svgElement);
                    } else {
                        reject(new Error("SVG element not found"));
                    }
                });
            });
        },

    },
};
</script>

<style lang="less" scoped>
.lawful-interception-guide {
    color: #ffffff;
    font-family: Arial, sans-serif;
    .markmapbox {
        
        height: 82vh;
        padding: 0 2vw;
        #markmapsvg {
            height: 100%;
            width: 100%;
            border-radius: 5px;
            background-color: #f1f1f5;
        }
    }
}
</style>