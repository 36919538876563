<template>
  <div class="modal-login" v-if="isVisible" @click.self="closeModal()">
    <div class="loginbox">
      <div class="colselogin" @click="closeModal()">×</div>
      <div class="modal-content">
        <h2>Sign Up</h2>
        <p>Create notes in minutes. Free forever. No credit card required.</p>

        <div
          id="g_id_onload"
          data-client_id="386542798820-4q9u2oko9k2ca2rnc7bh3hf1cqo2ta7b.apps.googleusercontent.com"
          data-login_uri="https://your.domain/your_login_endpoint"
          data-auto_prompt="false"
        ></div>
        <div
          class="g_id_signin"
          data-type="standard"
          data-size="large"
          data-theme="filled_black"
          data-text="continue_with"
          data-shape="rectangular"
          data-logo_alignment="center"
        ></div>

        <!-- <button class="google-button">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-google"></use>
          </svg>
          Continue with Google
        </button> -->

        <div class="divider"><span>OR</span></div>

        <div class="input-group">
          <input type="text" placeholder="First name" v-model="firstName" />
          <input type="text" placeholder="Last name" v-model="lastName" />
        </div>

        <input type="email" placeholder="Email" v-model="email" />
        <input type="password" placeholder="Password" v-model="password" />

        <button class="create-account">Create an account</button>

        <p>Already have an account? <a href="#">Sign in</a></p>
      </div>
      <p class="terms">
        By creating or entering an account, you agree to the
        <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",

      isVisible: false,
    };
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
// .modal-login {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.8);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   .loginbox {
//     background-color: rgb(14 16 20);
//     padding: 80px;
//     position: relative;
//     .colselogin {
//       position: absolute;
//       right: 25px;
//       top: 20px;
//       font-size: 30px;
//       cursor: pointer;
//     }
//   }
//   .modal-content {
//     box-shadow: 0px 0px 2px #eee;
//     border-radius: 15px;
//     padding: 20px;
//     color: white;
//     margin: 0px auto;
//     width: 350px !important;
//   }

//   h2 {
//     margin-bottom: 10px;
//     font-size: 30px;
//   }

//   p {
//     color: #b5bac1;
//     margin-bottom: 20px;
//   }

//   .google-button {
//     width: 100%;
//     padding: 10px;
//     border: 1px solid #65798d;
//     background-color: rgb(16 21 26);
//     border-radius: 4px;
//     color: white;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-bottom: 20px;
//     .icon {
//       font-size: 20px;
//       margin-right: 10px;
//     }
//     img {
//       margin-right: 10px;
//     }
//   }

//   .divider {
//     text-align: center;
//     margin: 38px 0;
//     height: 0px;
//     color: #b5bac1;
//     border: 1px solid #65798d;
//     position: relative;
//     span {
//       position: absolute;
//       top: -8px;
//       padding: 0px 10px;
//       left: 50%;
//       translate: -50%;
//       background-color: rgb(14 16 20);
//     }
//   }

//   .input-group {
//     display: flex;
//     gap: 10px;
//     margin-bottom: 10px;
//   }

//   input {
//     width: calc(100% - 24px);
//     padding: 12px;
//     border: 1px solid #65798d;
//     background-color: rgb(16 21 26);
//     border-radius: 8px;
//     color: white;
//     margin-bottom: 10px;
//   }

//   .create-account {
//     width: 100%;
//     padding: 10px;
//     background-color: #5865f2;
//     border: none;
//     border-radius: 10px;
//     color: white;
//     cursor: pointer;
//     margin-bottom: 20px;
//   }

//   a {
//     color: #00aff4;
//     text-decoration: none;
//   }

//   .terms {
//     font-size: 12px;
//     text-align: center;
//   }
// }
</style>
