var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pricing-container"},[(false)?_c('div',{staticClass:"free"},[_c('h1',[_vm._v("Limited time free access to all features.")])]):_vm._e(),(true)?_c('div',[_vm._m(0),(false)?_c('div',{staticClass:"billing-toggle"},[_c('button',{class:{ active: _vm.billing === 'yearly' },on:{"click":function($event){_vm.billing = 'yearly'}}},[_vm._v(" Yearly ")]),_c('button',{class:{ active: _vm.billing === 'monthly' },on:{"click":function($event){_vm.billing = 'monthly'}}},[_vm._v(" Monthly ")])]):_vm._e(),_c('div',{staticClass:"plans"},_vm._l((_vm.plans),function(plan){return _c('div',{key:plan.name,staticClass:"plan",class:{ highlight: plan.name === 'Annual' }},[(plan.name === 'Annual')?_c('div',{staticClass:"badge"},[_vm._v("Save 60%")]):_vm._e(),_c('h2',{class:{ h2red: plan.name !== 'Starter' }},[_vm._v(_vm._s(plan.name))]),_c('div',{staticClass:"price"},[(plan.price['dingyue'] != 'Free')?_c('span',[_vm._v("$")]):_vm._e(),_vm._v(_vm._s(plan.price["dingyue"])+" "),(plan.price[_vm.billing] !== 'Free')?_c('span',[_vm._v("Per Month")]):_vm._e()]),(false)?_c('div',{class:{ billing: true, billinghl: plan.name === 'Unlimited' }},[_vm._v(" Billed "+_vm._s(_vm.billing === "yearly" ? "Yearly" : "Monthly")+" ")]):_vm._e(),_c('ul',_vm._l((plan.features),function(feature){return _c('li',{key:feature},[_c('span',{staticClass:"checkmark"}),_vm._v(" "+_vm._s(feature)+" ")])}),0),(plan.price['dingyue'] == 'Free')?_c('button',{class:{
          subscribe: true,
          subhighlight: plan.name === 'Unlimited',
        },on:{"click":function($event){return _vm.gohomepage()}}},[_c('span',[_vm._v("Use Now")])]):_c('button',{class:{
          subscribe: true,
          subhighlight: plan.name === 'Unlimited',
        },on:{"click":function($event){return _vm.subscribe(plan)}}},[_c('span',[_vm._v("Subscribe Now")])])])}),0)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',[_vm._v(" Get "),_c('span',[_vm._v("Unlimited")]),_vm._v(" audio recordings, notes, PDFs and flashcards ")])
}]

export { render, staticRenderFns }