import { render, staticRenderFns } from "./popup_chaoxian.vue?vue&type=template&id=4780814c"
import script from "./popup_chaoxian.vue?vue&type=script&lang=js"
export * from "./popup_chaoxian.vue?vue&type=script&lang=js"
import style0 from "./popup_chaoxian.vue?vue&type=style&index=0&id=4780814c&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports