<template>
    <div class="realtimeaudio">
        <div class="backhome" @click="backhomepage"></div>
        <div class="gosupports" v-if="outlanjie" @click="clickleft_tipsave('supports')"></div>
        <div class="gosetting" v-if="outlanjie" @click="clickleft_tipsave('setting')"></div>
        <div class="gouserinfo" v-if="outlanjie" @click="clickleft_tipsave('userinfo')"></div>
        <div class="recordtotext">
            <div class="titlebox" v-if="false">
                <div class="websocketbtnbox">
                    <el-select v-model="zhandianurl" clearable placeholder="选择站点">
                        <el-option v-for="item in zhandianobj" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="appkey" clearable placeholder="选择模型语言">
                        <el-option v-for="item in appkeyarr" :key="item.value" :label="item.zhName" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-input style="width: 200px;" placeholder="断句间隔(200～2000)" v-model="maxSentenceSilence" clearable>
                    </el-input>
                    <el-button class="openwebsocket" :disabled="isConnected" @click="connectWebSocket">
                        {{ isConnected ? '识别中...' : '开启识别' }}
                    </el-button>
                    <el-checkbox v-model="isyuyiduanju">语义断句</el-checkbox>
                    <el-input placeholder="Token" v-model="realtimetoken" clearable></el-input>
                </div>
                <div class="t_right">
                    Translate in :
                </div>
            </div>
            <div class="alltextbox" ref="alltextbox">
                <div class="textbox" v-for="(textobj, i) in textobjs" :key="i">
                    <h5>{{ textobj.time }}</h5>
                    <div class="yuantext">{{ textobj.text }}</div>
                    <div class="translatetext"> {{ textobj.trans }}</div>
                </div>
                <div v-if="streamobj.stream_text" class="textbox">
                    <h5>{{ streamobj.stream_time }}</h5>
                    <div class="yuantext">{{ streamobj.stream_text }}</div>
                    <div class="translatetext"> {{ streamobj.stream_trans }}</div>
                </div>
            </div>
        </div>
        <div class="recordlecture">
            <div class="toptips">
                <h1>
                    <img :src="record_icon" alt="">
                    Live Transcript
                </h1>
                <p>Make sure the recording is longer than <span style="color: red;font-size: 1.6vw;">1</span> minute.
                </p>
                <p>Time Max : {{ maxrecordtime }}minutes</p>
            </div>


            <div class="notradio" v-show="radiostatus == 'notradio'">
                <div @click="connectWebSocket" class="yuan_btn record-button1">
                    <i class="el-icon-microphone"></i>
                </div>
                <div class="recordin">
                    <p>Record in:</p>
                    <el-select :disabled="elapsedTime > 0" v-model="appkey" class="selecttool" placeholder="language">
                        <el-option v-for="item in appkeyarr" :key="item.value" :label="item.name" :value="item">
                        </el-option>
                    </el-select>
                </div>
                <!-- <el-select style="width: 200px; margin-top: 10px" v-model="zhandianurl" clearable placeholder="选择站点">
                    <el-option v-for="item in zhandianobj" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select> -->
                <!-- <el-input style="width: 200px; margin-top: 10px" placeholder="Token" v-model="realtimetoken"
                    clearable></el-input> -->

            </div>
            <div class="startradio" v-show="radiostatus == 'startradio'">
                <div class="waveform">
                    <canvas ref="waveformCanvas" width="250" height="100"></canvas>
                </div>
                <div class="timer">{{ formattedTime }}</div>
                <div class="controls">
                    <div @click="toggleRecording()" class="yuan_btn record-button">
                        <i v-if="isRecording" class="el-icon-video-pause"></i>
                        <i v-else class="el-icon-microphone record-button-microphone"></i>
                    </div>
                    <div @click="stopRecording" class="yuan_btn stop-button" :disabled="!audioUrl">
                        <i></i>
                    </div>
                    <div @click="clearRecording" class="clear-button" :disabled="!audioUrl">
                        restart
                    </div>
                </div>
            </div>
            <div class="stopradio" v-show="radiostatus == 'stopradio'">
                <div class="waveform">
                    <canvas ref="waveformCanvas_R" width="250" height="100"></canvas>
                    <audio ref="audioPlayer" :src="audioUrl" @timeupdate="onTimeUpdate" @ended="onAudioEnded"></audio>
                </div>
                <div>
                    <span>{{ formatTime(currentTime) }} / {{ formatTime(elapsedTime) }}</span>
                </div>
                <div class="controls">
                    <div @click="togglePlay()" class="record-button">
                        <i v-if="!isPlaying" class="el-icon-video-play"></i>
                        <i v-else class="el-icon-video-pause"></i>
                    </div>
                    <div @click="clearRecording" class="clear-button" :disabled="!audioUrl">
                        restart
                    </div>

                </div>
                <div class="overaudiobox">

                    <el-button v-loading="isloading" element-loading-spinner="el-icon-loading"
                        :element-loading-text="loadingText" element-loading-background="rgba(21, 23, 32, 0.8)"
                        @click="openrecordtool" class="generate-notes-button" :disabled="!audioUrl">
                        Generate Notes
                        
                    </el-button>
                    <span :class="{ tipred: true, shake: tipredshow }" v-if="tipredshow">The recording is less than 1
                        minute</span>
                    <span :class="{ tipred: true, shake: uploadstatus == 'error' }" v-if="uploadstatus == 'error'"><i
                            class="el-icon-warning"></i>Upload fail. Save audio locally. <br> Retry later
                        with a better network connection. </span>
                    <el-button @click="downloadAudio" class="clear-button download-button"
                        :disabled="!audioUrl || uploadstatus == 'uploading'">
                        Save audio locally
                    </el-button>

                </div>

            </div>
            <div class="tipbox">
                Tips:<br>Do not close the tab while your recording is in progress. <br>
                For best results, please <span @click="qiehuanzhan">record</span> in a quiet area and position your
                device close to the sound.
            </div>
        </div>
        <popupRecord ref="pRecord" @chooeselanguage="chooeselanguage" :loadingText="loadingText"
            :recordtool="recordtool" />
        <popupchaoxian ref="fristSave" :textcontent="fristSavetext" @saveaudio="downloadAudio" />
        <popupchaoxian ref="leaveSave" :textcontent="leaveSavetext" :tonextname="leave_tonext" @saveaudio="saveaudio"
            @confirm="saveData" @cancel="cancelSave" />
    </div>
</template>

<script>
import IndexedDBAudioStorage from "@/assets/js/indexedDB.js";
import popupRecord from "@/views/pages/home_page/popups/popup_record_tool.vue";
import popupchaoxian from "@/views/pages/home_page/popups/popup_record_leave.vue";
export default {
    components: {
        popupRecord,
        popupchaoxian,
    },
    props: {
        uploadProgress: {
            type: Number,
            default: 0,
        },
        uploadstatus: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            record_icon: require("@/assets/images/home/record_icon.png"),
            // notradio startradio stopradio
            radiostatus: "notradio",

            isRecording: false,
            isPaused: false,
            stream: null,
            mediaRecorder: null,
            audioUrl: null,
            startTime: 0,
            elapsedTime: 0,
            pauseTime: 0,
            timer: null,
            audioContext: null,
            analyser: null,
            dataArray: null,

            canvasContext: null,
            animationFrame: null,
            isPlaying: false,
            currentTime: 0,
            playbackAnalyser: null,
            playbackDataArray: null,
            playbackAnimationFrame: null,
            playaudioContext: null,
            audioSource: null,
            isloading: false,
            tipredshow: false,

            textobjs: [
                // {
                //     text: "asdasdasdasdasda asdasda  asdasdasds",
                //     time: '00:11',
                //     trans: 'asdasdasdasdasda asdasda  asdasdasds'
                // },
                // {
                //     text: "哈哈哈；较发达；地方啊快点减肥；阿煎豆腐； 阿接受的；阿列克煎豆腐 asdasda  asdasdasds",
                //     time: '00:16',
                //     trans: 'asdasdasdasdasda asdasda  asdasdasds'
                // }
            ],
            mysocket: null,
            streamobj: { stream_time: "", stream_text: "", stream_trans: "" },
            message_id: "05450bf69c53413f8d88aed1ee60abcd",
            task_id: "640bc797bb684bd6960185651307qwer",
            scriptProcessor: null,
            appkeyarr: [
                { code: "en", name: "English", zhName: "英语", value: "3G3o7AlY1ncgnW8b" },
                { code: "zh", name: "中文", zhName: "中文", value: "zEURyO5izWCp17u9" },
                { code: "yue", name: "粤语", zhName: "粤语", value: "kV0JzwGpXHxGjMk6" },
                { code: "ru", name: "Russian", zhName: "俄语", value: "MdjmRjtynQQzfLmr" },
                { code: "ja", name: "Japanese", zhName: "日语", value: "03OLyZgpZdSbw2o2" },
                { code: "ko", name: "Korean", zhName: "韩语", value: "Qr672JcoALV09g5H" },
                { code: "de", name: "German", zhName: "德语", value: "XGBANgEKxxTEGSkR" },
                { code: "es", name: "Spanish", zhName: "西班牙语", value: "mf07QEy4zMvKNiCA" },
                { code: "fr", name: "French", zhName: "法语", value: "5WE4hnc6dzWI5o2C" },
                { code: "it", name: "Italian", zhName: "意大利语", value: "zhbOHHj9I3ejSOWk" },
                { code: "ar", name: "Arabic", zhName: "阿拉伯语", value: "40KHVNDUGFQw47E1" },
                { code: "hi", name: "Hindi", zhName: "印地语", value: "c7Gb2aadvsMjhqkn" },
                // { code: "pt", name: "Portuguese", zhName: "葡萄牙语", value: "Q1OQu8IdrxVTleWI" },
                // { code: "nl", name: "Dutch", zhName: "荷兰语", value: "NF9tLDmn7t9Yz1Ro" },
                // { code: "tr", name: "Turkish", zhName: "土耳其语", value: "mVMK8FtiDkohE4xw" },
                // { code: "kk", name: "Kazakh", zhName: "哈萨克语", value: "E8VE0jfSnJiliBqz" },
                // { code: "vi", name: "Vietnamese", zhName: "越南语", value: "pNAnqLsils8rm4CB" },
                // { code: "tl", name: "Filipino", zhName: "菲律宾语", value: "SOAnloTzZDpbtroU" },
                // { code: "ms", name: "Malay", zhName: "马来语", value: "npUaYoCa6S1yetiB" }
            ],
            appkey: {},
            maxSentenceSilence: '2000',
            isyuyiduanju: false,
            zhandianobj: [{
                value: 'nls-gateway-singapore.aliyuncs.com',
                label: '新加坡'
            }, {
                value: 'nls-gateway.cn-shanghai.aliyuncs.com',
                label: '上海'
            }],
            zhandianurl: 'nls-gateway-singapore.aliyuncs.com',
            realtimetoken: '',


            websocket: null,
            taskId: null,
            messageId: null,
            isConnected: false,
            chunks: [],
            chunkInterval: 60000,
            chunkCounter: 0,
            maxrecordtime: 60,
            liveMinutes: 0,
            keepAliveInterval: null,
            lastActiveTime: null,
            pcmData: [],

            chooeseLanguage: "",
            chooeseLanguageName: "",
            recordtool: 'title',

            fristSavetext: "The recording will only be saved for <span class='redstrong'>24 hours</span>.<br> If the recording is important to you, please <span class='redstrong'>save the audio locally</span> before generating notes.",
            leaveSavetext: "Leaving this page will lose the unsaved audio. <br>Do you want to save the audio file locally?",
            leave_tonext: "",

            mtype: 0,

            outlanjie: false,
        };
    },
    computed: {
        formattedTime() {
            const minutes = Math.floor(this.elapsedTime / 60);
            const seconds = this.elapsedTime % 60;
            return `${minutes.toString().padStart(2, "0")}:${seconds
                .toString()
                .padStart(2, "0")}`;
        },
        loadingText() {
            return `Uploading... ${this.uploadProgress}%`;
        },
        islangtime() {
            // if (this.elapsedTime > this.liveMinutes) {
            //     return true;
            // }
            return this.elapsedTime > 60 * this.maxrecordtime;
        },
    },
    mounted() {

        this.canvasContext = this.$refs.waveformCanvas.getContext("2d");
        this.mtype = this.$cookies.get("m_tkn", 0);
        if (this.mtype == 0) {
            this.maxrecordtime = 15;
        } else {
            this.maxrecordtime = 180;
        }

        // this.fetchLiveMinutes();
        this.fetchKey();
    },
    watch: {
        textobjs: {
            handler() {
                this.scrollToBottom();
            },
            deep: true
        },
        streamobj: {
            handler() {
                this.scrollToBottom();
            },
            deep: true
        }
    },
    methods: {
        qiehuanzhan() {
            this.zhandianurl = this.zhandianobj[1].value;
        },
        async fetchLiveMinutes() {
            try {
                const subid = this.$cookies.get("subid", "");
                const response = await this.$axios.post('/api/getliveminutes/', {
                    subid: subid,
                });
                const resdata = response.data;
                if (resdata.code === 200) {
                    // this.configuredMinutes = response.data.configured_minutes;
                    // this.uploadedMinutes = response.data.uploaded_minutes;
                    // console.log(resdata);
                    this.liveMinutes = resdata.configured_minutes - resdata.uploaded_minutes;
                }
            } catch (error) {
                console.error('Error fetching live minutes:', error);
                return;
            }
        },
        leaveSave(tonext) {
            if (this.elapsedTime > 10) {
                this.leave_tonext = tonext;
                this.$refs.leaveSave.openModal();
                return '';
            } else {
                return tonext;
            }
            // this.$parent.showhome = "radio";
        },
        async fetchKey() {
            try {
                const response = await this.$axios.get('https://www.easynoteai.com/api/get_key');
                const data = response.data;
                this.realtimetoken = data.token;
            } catch (error) {
                console.error('Error fetching key:', error);
            }
        },

        backhomepage() {
            if (this.outlanjie ) {
                this.$refs.leaveSave.openModal();
            } else {
                this.$emit("backhomepage");
            }

        },
        clickleft_tipsave(key) {
            console.log(key);
            
            this.$refs.leaveSave.openModal();
        },
        togglePlay() {
            if (this.isPlaying) {
                this.$refs.audioPlayer.pause();
                cancelAnimationFrame(this.playbackAnimationFrame);
            } else {
                this.$refs.audioPlayer
                    .play()
                    .then(() => {
                        this.setupPlaybackAnalyser();
                        this.drawPlaybackWaveform();
                    })
                    .catch((error) => {
                        console.error("Error playing audio:", error);
                    });
            }
            this.isPlaying = !this.isPlaying;
        },
        formatTime(time) {
            const minutes = Math.floor(time / 60);
            const seconds = Math.floor(time % 60);
            return `${minutes.toString().padStart(2, "0")}:${seconds
                .toString()
                .padStart(2, "0")}`;
        },
        formatDate_ymd(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        onAudioEnded() {
            this.isPlaying = false;
            cancelAnimationFrame(this.playbackAnimationFrame);
        },
        onTimeUpdate(e) {
            this.currentTime = e.target.currentTime;
        },
        async toggleRecording() {
            if (!this.isRecording && !this.isPaused) {
                await this.startRecording();
            } else if (this.isRecording && !this.isPaused) {
                this.pauseRecording();
            } else if (this.isPaused) {
                this.resumeRecording();
            }
        },
        async saveChunk(chunk) {
            try {
                await IndexedDBAudioStorage.saveChunk(chunk);
            } catch (error) {
                console.error("Error saving chunk:", error);
            }
        },
        async startRecording() {
            if (this.radiostatus != "startradio") {
                this.radiostatus = "startradio";
                this.$cookies.set('ischilddeep', 'islive')
                this.outlanjie = true;
            }
            try {
                this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                this.audioContext = new (window.AudioContext ||
                    window.webkitAudioContext)({ sampleRate: 16000 });
                const source = this.audioContext.createMediaStreamSource(this.stream);
                this.analyser = this.audioContext.createAnalyser();
                this.analyser.fftSize = 2048;
                source.connect(this.analyser);
                this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
                this.mediaRecorder = new MediaRecorder(this.stream);
                this.mediaRecorder.addEventListener("dataavailable", async (event) => {
                    if (event.data.size > 0) {
                        // this.chunks.push(event.data);
                        await this.saveChunk(event.data);
                        this.chunkCounter++;
                    }
                    if (this.islangtime) {
                        this.openvipchaoxian();
                        this.stopRecording();
                    }
                });
                const scriptProcessor = this.audioContext.createScriptProcessor(2048, 1, 1)
                scriptProcessor.onaudioprocess = (event) => {
                    if (!this.isRecording) {
                        return;
                    }
                    const inputData = event.inputBuffer.getChannelData(0);
                    const inputData16 = new Int16Array(inputData.length);
                    for (let i = 0; i < inputData.length; ++i) {
                        inputData16[i] = Math.max(-1, Math.min(1, inputData[i])) * 0x7FFF;
                    }
                    if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                        this.lastActiveTime = Date.now();
                        this.websocket.send(inputData16.buffer);
                    }
                    // this.pcmData.push(...inputData16);
                };
                source.connect(scriptProcessor);
                scriptProcessor.connect(this.audioContext.destination);
                this.mediaRecorder.addEventListener("stop", async () => {

                    if (this.isRecording && this.mediaRecorder?.state === 'inactive') {
                        this.mediaRecorder.start();
                    } else {
                        await this.finalizeRecording();
                    }
                });
                this.mediaRecorder.start(1000);
                this.isRecording = true;
                this.isPaused = false;
                if (this.pauseTime != 0) {
                    this.startTime += Date.now() - this.pauseTime;
                    this.pauseTime = 0;
                } else {
                    this.startTime = Date.now();
                }
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(() => {
                    this.elapsedTime = Math.floor((Date.now() - this.startTime) / 1000);
                    if (this.islangtime) {
                        this.openvipchaoxian();
                        this.stopRecording();
                    }
                }, 1000);

                this.drawWaveform();


            } catch (err) {
                console.error("Error accessing microphone:", err);
            }
        },
        async pauseRecording() {
            if (this.isRecording && !this.isPaused && this.mediaRecorder.state === "recording") {
                this.mediaRecorder.pause();
                this.isPaused = true;
                this.isRecording = false;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.pauseTime = Date.now();
            }
        },
        resumeRecording() {
            if (this.websocket && this.websocket.readyState === WebSocket.CLOSED) {
                this.connectWebSocket();
            }
            if (this.isPaused && this.mediaRecorder.state === "paused") {
                this.mediaRecorder.resume();
                this.isPaused = false;
                this.isRecording = true;
                this.startTime += Date.now() - this.pauseTime;
                if (this.timer) {
                    clearInterval(this.timer);
                }
                this.timer = setInterval(() => {
                    this.elapsedTime = Math.floor((Date.now() - this.startTime) / 1000);
                    if (this.islangtime) {
                        this.openvipchaoxian();
                        this.stopRecording();
                    }
                }, 1000);
                this.audioContext
                    .resume()
                    .then(() => {
                        this.drawWaveform();
                    })
                    .catch((error) =>
                        console.error("Error resuming audio context:", error)
                    );
            }
        },
        stopRecording() {
            this.radiostatus = "stopradio";
            this.pauseTime = 0;
            this.isRecording = false;
            this.isPaused = false;
            if (this.mediaRecorder) {
                this.mediaRecorder.stop();
                this.mediaRecorder.onstop = () => {
                    // 关闭麦克风
                    if (this.stream) {
                        this.stream.getTracks().forEach(track => track.stop());
                    }
                };
            }
            this.mediaRecorder = null;
            this.stream = null;

            this.streamobj = { stream_time: "", stream_text: "", stream_trans: "" }
            if (this.timer) {
                clearInterval(this.timer);
            }
            clearInterval(this.keepAliveInterval);
            cancelAnimationFrame(this.animationFrame);
            if (this.audioContext && this.audioContext.state !== "closed") {
                this.audioContext
                    .close()
                    .catch((error) =>
                        console.error("Error closing audio context:", error)
                    );
            }
            if (this.analyser) {
                this.analyser.disconnect();
                this.analyser = null;
            }
            if (this.websocket) {
                this.websocket.close();
            }
            this.audioContext = null;
        },
        generateUUID() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            ).replace(/-/g, '');
        },
        connectWebSocket() {
            if (!this.zhandianurl) {
                this.$message.error('请选择站点'); return;
            }
            if (!this.appkey || !this.appkey.value) {
                this.$message.error('Please select the audio language first.'); return;
            }
            const mss_num = this.maxSentenceSilence * 1;
            if (!mss_num || mss_num < 200 || mss_num > 2000) {
                this.$message.error('请输入合法断句的时长'); return;
            }

            // console.log('查看参数', this.zhandianurl, this.appkey, this.maxSentenceSilence, mss_num, this.isyuyiduanju, this.realtimetoken);
            const socketUrl = `wss://${this.zhandianurl}/ws/v1?token=${this.realtimetoken}`;
            this.taskId = this.generateUUID();
            this.messageId = this.generateUUID();
            this.websocket = new WebSocket(socketUrl);
            this.websocket.onopen = async () => {
                console.log('连接到 WebSocket 服务器', socketUrl);
                if (this.mediaRecorder == null) {
                    await IndexedDBAudioStorage.clearDB();
                    this.startRecording();
                }

                const startTranscriptionMessage = {
                    header: {
                        appkey: this.appkey.value,
                        namespace: "SpeechTranscriber",
                        name: "StartTranscription",
                        task_id: this.taskId,
                        message_id: this.messageId
                    },
                    payload: {
                        format: "pcm",
                        sample_rate: 16000,
                        enable_intermediate_result: true,
                        enable_punctuation_prediction: true,
                        enable_inverse_text_normalization: true,
                        max_sentence_silence: mss_num,
                        enable_semantic_sentence_detection: this.isyuyiduanju
                    }
                };
                if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                    // console.log('发送消息: ' + JSON.stringify(startTranscriptionMessage));
                    this.websocket.send(JSON.stringify(startTranscriptionMessage));
                }
                this.lastActiveTime = Date.now();
                this.keepAliveInterval = setInterval(() => {
                    if (Date.now() - this.lastActiveTime > 60000) {
                        this.disconnectWebSocket();
                    } else {
                        this.sendSilence();
                    }
                }, 10000);
            };
            this.websocket.onmessage = (event) => {
                const message = JSON.parse(event.data);
                if (message.header.name === "TranscriptionStarted") {
                    this.isConnected = true;
                } else if (message.header.name === 'TranscriptionResultChanged') {
                    if (this.isRecording) {
                        this.streamobj.stream_text = message.payload.result;
                        if (!this.streamobj.stream_time) {
                            this.streamobj.stream_time = this.formattedTime;
                        }
                        this.streamobj.stream_trans = '';
                    }

                } else if (message.header.name === 'SentenceEnd') {
                    // console.log('一句话结束:', message.payload);
                    const pushmsg = {
                        time: this.streamobj.stream_time,
                        text: message.payload.result,
                        trans: this.streamobj.stream_trans,
                    };
                    this.textobjs.push(pushmsg);
                    this.streamobj = { stream_time: "", stream_text: "", stream_trans: "" }
                }
            };
            this.websocket.onerror = (event) => {
                console.log('WebSocket 错误: ' + event);
                this.fetchKey();
                this.$message.error('The network connection is unstable, please try again.');
            };
            this.websocket.onclose = () => {
                console.log('与 WebSocket 服务器断开');
                this.isConnected = false;
            };
        },
        sendSilence() {
            const silenceData = new Int16Array(2048);
            if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
                this.websocket.send(silenceData.buffer);
            }
        },
        disconnectWebSocket() {
            if (this.websocket) {
                this.websocket.close();
                clearInterval(this.keepAliveInterval);
            }
            this.isConnected = false;
        },
        async clearRecording() {
            this.$cookies.set('ischilddeep', '')
            this.audioUrl = null;

            this.tipredshow = false;
            this.pauseTime = 0;
            this.isPlaying = false;
            this.isPaused = false;
            this.currentTime = 0;
            this.$parent.uploadstatus = ''

            if (this.timer) {
                clearInterval(this.timer);
            }
            this.elapsedTime = 0;
            this.canvasContext.clearRect(
                0,
                0,
                this.$refs.waveformCanvas.width,
                this.$refs.waveformCanvas.height
            );
            if (this.mediaRecorder) {
                this.mediaRecorder.stop();
                this.mediaRecorder.onstop = () => {
                    // 关闭麦克风
                    if (this.stream) {
                        this.stream.getTracks().forEach((track) => track.stop());
                    }
                    this.isRecording = false;
                };
            }
            this.mediaRecorder = null;
            this.stream = null;
            await IndexedDBAudioStorage.clearDB();
            if (this.audioContext && this.audioContext.state !== "closed") {
                this.audioContext
                    .close()
                    .catch((error) =>
                        console.error("Error closing audio context:", error)
                    );
            }
            if (this.analyser) {
                this.analyser.disconnect();
                this.analyser = null;
            }

            this.streamobj = { stream_time: "", stream_text: "", stream_trans: "" }
            this.textobjs = [];

            this.cleanupAudioContext();

            this.radiostatus = "notradio";
        },
        cleanupAudioContext() {
            this.$refs?.audioPlayer?.pause();
            if (this.audioSource) {
                this.audioSource.disconnect();
                this.audioSource = null;
            }
            if (this.playbackAnalyser) {
                this.playbackAnalyser.disconnect();
                this.playbackAnalyser = null;
            }
            if (this.playaudioContext && this.playaudioContext.state !== "closed") {
                this.playaudioContext
                    .close()
                    .catch((error) =>
                        console.error("Error closing audio context:", error)
                    );
            }
            this.playaudioContext = null;
            this.playbackDataArray = null;
            cancelAnimationFrame(this.animationFrame);
            cancelAnimationFrame(this.playbackAnimationFrame);
        },
        async finalizeRecording() {
            try {
                const chunks = await IndexedDBAudioStorage.getAllChunks();
                // console.log("All chunks retrieved successfully", chunks);
                const audioBlob = new Blob(chunks, { type: "audio/webm" });
                this.audioUrl = URL.createObjectURL(audioBlob);
            } catch (error) {
                console.error("Error finalizing recording:", error);
            }
        },
        async downloadAudio(tonext = '') {

            await this.finalizeRecording();
            if (this.audioUrl) {
                const a = document.createElement("a");
                a.href = this.audioUrl;
                a.download = "audio_" + Date.now() + ".webm";
                a.click();
            }

            if (tonext !== '') {
                console.log(tonext);

            }
        },
        openrecordtool() {
            if (this.elapsedTime < 60) {
                this.tipredshow = true;
                return;
            }
            const isfirst_g = this.$cookies.get("isfirst_g");
            if (!isfirst_g) {
                this.$cookies.set("isfirst_g", true, { expires: 30 });
                this.$refs.fristSave.openModal();
            }

            this.recordtool = 'language'
            this.$refs.pRecord.openModal();
        },
        chooeselanguage(language, Languagename) {
            this.chooeseLanguage = language
            this.chooeseLanguageName = Languagename
            this.$emit("chooeselanguagein", this.appkey.code, this.appkey.name);
            this.$emit("chooeselanguage", language, Languagename);
            this.generateNotes();
        },
        async generateNotes() {
            const chunks = await IndexedDBAudioStorage.getAllChunks();
            await IndexedDBAudioStorage.clearDB();
            if (!chunks) {
                console.error("No recording to upload");
                return;
            }
            this.stopRecording();

            this.isloading = true;
            const audioBlob = new Blob(chunks);
            const name = "audio_" + Date.now() + ".mp3";
            // console.log('recode-time', this.elapsedTime);
            this.$emit("upload_audio", 'radio', audioBlob, name, this.elapsedTime, this.textobjs);
        },
        setupPlaybackAnalyser() {
            if (!this.playaudioContext || this.playaudioContext.state === "closed") {
                this.playaudioContext = new (window.AudioContext ||
                    window.webkitAudioContext)();
                this.audioSource = null;
                this.playbackAnalyser = null;
            }
            if (this.playaudioContext.state === "suspended") {
                this.playaudioContext.resume();
            }
            if (!this.playbackAnalyser) {
                this.playbackAnalyser = this.playaudioContext.createAnalyser();
                this.playbackAnalyser.fftSize = 2048;
            }
            if (!this.audioSource) {
                this.audioSource = this.playaudioContext.createMediaElementSource(
                    this.$refs.audioPlayer
                );
                this.audioSource.connect(this.playbackAnalyser);
                this.playbackAnalyser.connect(this.playaudioContext.destination);
            }
            this.playbackDataArray = new Uint8Array(
                this.playbackAnalyser.frequencyBinCount
            );
        },
        drawPlaybackWaveform() {
            if (!this.playbackAnalyser) return;
            this.playbackAnalyser.getByteTimeDomainData(this.playbackDataArray);
            const canvas = this.$refs.waveformCanvas_R;
            const canvasCtx = canvas.getContext("2d");
            const width = canvas.width;
            const height = canvas.height;
            canvasCtx.fillStyle = "#15171f";
            canvasCtx.fillRect(0, 0, width, height);
            canvasCtx.lineWidth = 2;
            canvasCtx.strokeStyle = "#ff4444";
            canvasCtx.beginPath();
            const sliceWidth = (width * 1.0) / this.playbackDataArray.length;
            let x = 0;
            for (let i = 0; i < this.playbackDataArray.length; i++) {
                const v = this.playbackDataArray[i] / 128.0;
                const y = (v * height) / 2;
                if (i === 0) {
                    canvasCtx.moveTo(x, y);
                } else {
                    canvasCtx.lineTo(x, y);
                }
                x += sliceWidth;
            }
            canvasCtx.lineTo(width, height / 2);
            canvasCtx.stroke();
            if (this.isPlaying) {
                this.playbackAnimationFrame = requestAnimationFrame(
                    this.drawPlaybackWaveform
                );
            }
        },
        drawWaveform() {
            if (!this.isRecording) {
                return;
            }
            this.analyser.getByteTimeDomainData(this.dataArray);
            this.canvasContext.fillStyle = "#15171f";
            this.canvasContext.fillRect(
                0,
                0,
                this.$refs.waveformCanvas.width,
                this.$refs.waveformCanvas.height
            );
            this.canvasContext.lineWidth = 2;
            this.canvasContext.strokeStyle = "#ff4444";
            this.canvasContext.beginPath();
            const sliceWidth =
                (this.$refs.waveformCanvas.width * 1.0) /
                this.analyser.frequencyBinCount;
            let x = 0;
            for (let i = 0; i < this.analyser.frequencyBinCount; i++) {
                const v = this.dataArray[i] / 128.0;
                const y = (v * this.$refs.waveformCanvas.height) / 2;
                if (i === 0) {
                    this.canvasContext.moveTo(x, y);
                } else {
                    this.canvasContext.lineTo(x, y);
                }
                x += sliceWidth;
            }
            this.canvasContext.lineTo(
                this.$refs.waveformCanvas.width,
                this.$refs.waveformCanvas.height / 2
            );
            this.canvasContext.stroke();
            this.animationFrame = requestAnimationFrame(this.drawWaveform);
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const element = this.$refs.alltextbox;
                element.scrollTop = element.scrollHeight;
            });
        },
        saveaudio() {

            this.outlanjie = false;
            this.$cookies.set('ischilddeep', '')
            if (this.radiostatus == 'startradio') {
                this.stopRecording();
            }
            if (this.radiostatus == 'stopradio') {
                this.downloadAudio();
            }
            this.$refs.leaveSave.closeModal();
        },
        saveData() {
            // 执行保存数据的逻辑

            if (this.radiostatus == 'startradio') {
                this.stopRecording();
                this.openrecordtool();
            }
            this.outlanjie = false;
            this.$cookies.set('ischilddeep', '')
            this.$refs.leaveSave.closeModal();


        },
        cancelSave(tonext) {
            console.log('cansel save', tonext);
            this.$cookies.set('ischilddeep', '')
            this.outlanjie = false;
            this.$refs.leaveSave.closeModal();
        },
        openvipchaoxian() {
            this.$emit('openvipchaoxian')
        },
    },
    beforeDestroy() {
        this.$cookies.set('ischilddeep', '')
        cancelAnimationFrame(this.animationFrame);
        cancelAnimationFrame(this.playbackAnimationFrame);
        clearInterval(this.keepAliveInterval);
        IndexedDBAudioStorage.clearDB()
        if (this.audioSource) {
            this.audioSource.disconnect();
        }
        if (this.playbackAnalyser) {
            this.playbackAnalyser.disconnect();
        }
        this.clearRecording();
    },
};
</script>

<style lang="less">
.realtimeaudio {
    display: flex;
    flex-direction: row;
}

.backhome {
    cursor: pointer;
    width: 220px;
    height: 135px;
    // border:1px solid #fff ;
    position: absolute;
    left: 0;
    top: 0;
}

.gosupports {
    cursor: pointer;
    width: 220px;
    height: 60px;
    // border:1px solid #fff ;
    position: absolute;
    left: 0;
    top: 135px;
}

.gosetting {
    cursor: pointer;
    width: 220px;
    height: 120px;
    // border:1px solid #fff ;
    position: absolute;
    left: 0;
    top: 250px;
}

.gouserinfo {
    cursor: pointer;
    width: 220px;
    height: 60px;
    // border:1px solid #fff ;
    position: absolute;
    left: 0;
    bottom: 0;
}

.recordlecture {
    color: white;
    padding: 1vw;
    text-align: center;
    width: 20vw;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .tipbox {
        font-size: 1rem;
        text-align: left;
        margin: 4vw auto 0;
        flex: 2;

        span {
            cursor: pointer;
        }
    }

    .toptips {
        flex: 4;

        h1 {
            margin: 0;
            margin-bottom: 10px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 26px;

            img {
                margin-right: 1vw;
                max-width: 2.8vw;
            }
        }

        p {
            font-size: 1vw;
            color: rgb(209 213 219);
            padding: 0 50px;
            line-height: 20px;
            margin-bottom: 20px;

        }
    }

    .yuan_btn {
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }

    .notradio {
        display: flex;
        flex-direction: column;
        align-items: center;
        // position: absolute;
        // top: 20vh;
        // left: 50%;
        // translate: -50%;
        // width: 100%;
        flex: 9;

        .record-button1 {
            cursor: pointer;
            background-color: #fff;
            margin-bottom: 1vw;

            i {
                font-size: 2vw;
                color: #15171f;
            }

            &:hover {
                background-color: #555658;

                i {
                    font-size: 2vw;
                    color: #fff;
                }
            }
        }

        .recordin {
            display: flex;
            align-items: center;

            p {
                margin-right: 0.5vw;
            }

            .selecttool {
                padding: 0;
                border: none;

                .el-input__inner {
                    height: 30px;
                    width: 150px;
                }

                .el-input__icon {
                    line-height: 30px;

                }
            }
        }
    }

    .startradio {
        // position: absolute;
        // top: 20vh;
        // left: 50%;
        // translate: -50%;
        // width: 100%;
        flex: 9;
    }

    .stopradio {
        flex: 9;
    }

    .waveform {
        height: 100px;
        margin: 0 auto;
        overflow: hidden;
        /* 隐藏溢出的内容 */
        position: relative;
        width: 250px;

        /* 相对定位 */
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 100px;
            height: 100%;
            z-index: 1;
        }

        &::before {
            left: 0;
            background: linear-gradient(to right, #15171f, transparent);
        }

        &::after {
            right: 0;
            background: linear-gradient(to left, #15171f, transparent);
        }
    }

    .clear-button {
        padding: 8px 13px;
        font-size: 18px;
        border-radius: 15px;
        font-weight: bold;
        color: #ff4f4f;
        background-color: #fff;
        margin-left: 1.5vw;
        cursor: pointer;

        &:hover {
            color: #fff;
            background-color: #f78080;
        }
    }

    .controls {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 1.5vw;

        .record-button {
            background: none;
            border: none;
            cursor: pointer;
            width: 50px;
            height: 50px;

            i {
                font-size: 50px;
                color: #ff4f4f;
            }

            .record-button-microphone {
                color: #fff;
            }

            &:hover {
                i {
                    opacity: 0.7;
                }
            }
        }

        .stop-button {
            background-color: #ff4f4f;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 2px solid #ccc;
            cursor: pointer;
            margin-left: 1vw;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                width: 1.2vw;
                height: 1.2vw;
                background-color: #fff;
                display: inline-block;
                border-radius: 6px;
            }

            &:hover {
                background-color: #e5a0a0;
            }
        }

        .download-button {
            width: 16vw;
            margin: 0;
            margin-top: 0.5vw;
            font-size: 1.2vw;
            padding: 1vw 1.2vw !important;
        }
    }

    .overaudiobox {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 2vw;

        .generate-notes-button {
            width: 16vw;
            min-width: 200px;
            padding: 10px 0;
            font-size: 18px;
            border-radius: 15px;
            border: none;
            font-weight: bold;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: rgb(22 163 74);
            margin-bottom: 0.5vw;
            cursor: pointer;
            position: relative;
            justify-content: center;

            i {
                font-size: 18px;
                position: absolute;
                right: 1vw;
            }

            &:hover {
                background-color: rgb(18 143 65);
            }
        }

        .download-button {
            width: 16vw;
            min-width: 200px;
            margin: 0;
            margin-top: 0.5vw;
            font-size: 18px;
            padding: 10px 0;
        }
    }

    @keyframes shake {

        0%,
        100% {
            transform: translateX(0);
        }

        25% {
            transform: translateX(-10px);
        }

        50% {
            transform: translateX(10px);
        }

        75% {
            transform: translateX(-10px);
        }
    }

    .tipred {
        display: inline-block;
        color: red;

        &.shake {
            animation: shake 0.3s;
        }
    }

    .generate-notes-button {

        font-size: 18px;
        border-radius: 20px;
        border: none;
        font-weight: bold;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: rgb(22 163 74);
        margin: 30px auto;
        cursor: pointer;

        i {
            font-size: 2wv;
            margin-left: 2vw;
        }
    }
}

.recordtotext {
    width: 60vw;
    min-width: 600px;
    color: #fff;
    padding: 0 1vw;

    .titlebox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0.3vw 0;

        .t_left {
            display: flex;
            align-items: center;

            h3 {
                margin: 0;

            }

            span {
                background-color: rgb(105, 182, 83);
                padding: 2px 10px;
                border-radius: 5px;
                margin-left: 1vw;
            }

            .timeover {
                background-color: #6c5ce7;
            }
        }
    }

    .websocketbtnbox {
        display: flex;
        align-items: center;

        * {
            margin-right: 0.7vw;
        }

    }

    .alltextbox {
        background-color: rgba(255, 255, 255, 0.1);
        padding: 1vw;
        border-radius: 5px;
        height: calc(100vh - 100px);
        overflow: auto;

        .textbox {
            font-size: 16px;

            h5 {
                margin: 0;
                text-align: center;
            }

            .yuantext {
                font-size: 16px;
                margin: 0.6vw 0;
            }

            .translatetext {
                margin-bottom: 0.8vw;
            }
        }
    }

}
</style>
