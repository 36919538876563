<template>
  <div class="note-settings">
    <h1>
      <!-- <i class="el-icon-setting"></i> -->
      Note Settings
    </h1>

    <div class="setting-item">
      <div class="setting-label">
        <h2>Notes Title</h2>
        <p>This title is visible to others when notes are public</p>
      </div>
      <div class="setting-value changebtn" @click="changetitle()">
        <img class="editicon" :src="editicon" alt="Edit" /> {{ noteTitle }}
      </div>
    </div>

    <div class="setting-item" v-if="false">
      <div class="setting-label">
        <h2>Notes Publicly Shared</h2>
        <p>Whether the notes are available to everyone</p>
      </div>
      <div class="setting-value">
        <label class="toggle">
          <input type="checkbox" checked />
          <span class="slider round"></span>
        </label>
      </div>
    </div>

    <div class="setting-item">
      <div class="setting-label">
        <h2>Date Uploaded</h2>
        <p>The date the lecture was uploaded</p>
      </div>
      <div class="setting-value">{{ formatDate(dataUploaded) }}</div>
    </div>

    <div class="setting-item">
      <div class="setting-label">
        <h2>Class Subject</h2>
        <p>The subject selected on upload</p>
      </div>
      <div class="setting-value">{{ classSubject }}</div>
    </div>

    <div class="delbtnbox">
      <button @click="deleteNotes" class="delete-button">Delete Notes</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "NoteSettings",
  props: {
    noteobj: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    noteobj: {
      handler(newobject, oldobject) {
        console.log(oldobject.notename);
        this.noteTitle = newobject.notename;
        this.dataUploaded = newobject.createtime;
        this.classSubject = newobject.subject;
      },
      deep: true, // 添加深度监听
    },
  },
  data() {
    return {
      editicon: require("@/assets/images/noteindex/edit_icon.png"),
      noteTitle: "Lawful Interception in Telecommunications Networks Overview",
      isPublic: true,
      dataUploaded: "2024-07-12",
      classSubject: "Computer Science",
    };
  },

  created() {
    this.noteTitle = this.noteobj.notename;
    this.dataUploaded = this.noteobj.createtime;
    this.classSubject = this.noteobj.subject;
  },

  methods: {
    changetitle() {
      // console.log("Change Title");
      this.$emit("openchangetitle");
    },
    deleteNotes() {
      // 实现删除笔记的逻辑
      this.$emit("deletenote");
    },
    formatDate(dateObject) {
      const date = new Date(dateObject);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
<style lang="less" scoped>
.note-settings {
  color: #ffffff;
  padding: 0 2vw;
  font-family: Arial, sans-serif;

  h1 {
    margin: 0;
    font-size: 36px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    i {
      margin-right: 10px;
      font-size: 44px;
    }
  }

  .setting-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8vw 1.2vw;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    margin-bottom: 0.8vw;
    &:last-child {
      border-bottom: none;
    }

    .setting-label {
      h2 {
        font-size: 1.2vw;
        margin: 0;
      }

      p {
        font-size: 0.9vw;
        color: rgb(156 163 175);
        margin: 5px 0 0;
      }
    }

    .setting-value {
      color: #fff;
      font-family: Outfit;
    }
    .changebtn {
      cursor: pointer;
      display: flex;
      align-items: center;
      img{
        width: 20px;
        margin-right: 10px;
      }
    }
  }

  .toggle {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 24px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: rgb(108, 92, 231);
      }

      &:checked + .slider:before {
        transform: translateX(40px);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: 0.4s;
      border-radius: 4px;

      &:before {
        position: absolute;
        content: "";
        height: 24px;
        width: 40px;
        left: 0px;
        bottom: 0px;
        background-color: white;
        transition: 0.4s;
        border-radius: 5px;
      }
    }
  }
  .delbtnbox {
    width: 100%;
    margin-top: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .delete-button {
    background-color: red;
    color: white;
    border: none;
    padding: 0.7vw 0px;
    width: 40%;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1vw;

    &:hover {
      background-color: #c82333;
    }
  }
}
.icon-gear:before {
  font-family: "icomoon";
  content: "\e900"; // 使用你的齿轮图标的 Unicode
}
</style>
