<template>
  <div class="video-container">
    <!-- <youtube :video-id="videoId" @ready="onReady" @playing="onPlaying" @paused="onPaused" @ended="onEnded"
      class="youtube-player"></youtube> -->
    <!-- <div class="controls">
      <button @click="playVideo" class="control-btn">Play</button>
      <button @click="pauseVideo" class="control-btn">Pause</button>
      <button @click="stopVideo" class="control-btn">Stop</button>
      <button @click="muteVideo" class="control-btn">Mute</button>
      <button @click="unMuteVideo" class="control-btn">Unmute</button>
      <input
        type="range"
        min="0"
        max="100"
        v-model="volume"
        @input="setVolume"
        class="volume-slider"
      />
    </div>
    <p v-if="message" class="message">{{ message }}</p> -->
    <video class="youtube-player" ref="videoPlayer" :src="videoSource" width="600" height="400" loop muted></video>
  </div>
</template>

<script>
export default {
  name: "YoutubePlayer",
  data() {
    return {
      videoSource: require('@/assets/video/3_3.mp4'),
      videoId: "zGekatBTLdA", // 提取的视频ID
      //   videoId: "4xDzrJKXOOY", // 提取的视频ID
      player: null,
      message: "",
      volume: 0, // 默认音量
    };
  },
  created() {
    // 组件创建时触发
    // this.playVideo();
  },
  mounted() {
    // 组件挂载完成后触发

    setTimeout(() => {
      this.playVideo();
    }, 100);

  },
  methods: {

    playVideo() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.play();
      }
    },
    pauseVideo() {
      this.$refs.videoPlayer.pause();
    },
    toggleLoop() {
      this.isLooping = !this.isLooping;
      this.$refs.videoPlayer.loop = this.isLooping;
    },
    // onReady(event) {
    //   // 视频准备就绪时触发
    //   this.player = event;
    //   this.player.mute(); // 静音
    //   this.player.playVideo(); // 自动播放
    // },
    // onPlaying() {
    //   // 视频开始播放时触发
    //   this.message = "Video is playing";
    // },
    // onPaused() {
    //   // 视频暂停时触发
    //   this.message = "Video is paused";
    // },
    // onEnded() {
    //   // 视频结束时触发
    //   this.message = "Video has ended";
    // },
    // playVideo() {
    //   if (this.player) {
    //     this.player.playVideo();
    //   }
    // },
    // pauseVideo() {
    //   this.player.pauseVideo();
    // },
    // stopVideo() {
    //   this.player.stopVideo();
    // },
    // muteVideo() {
    //   this.player.mute();
    // },
    // unMuteVideo() {
    //   this.player.unMute();
    // },
  },
};
</script>

<style>
.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
}

.youtube-player {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.control-btn {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.control-btn:hover {
  background-color: #0056b3;
}

.volume-slider {
  width: 100px;
  height: 10px;
  border-radius: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.volume-slider:hover {
  opacity: 1;
}

.message {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}
</style>
