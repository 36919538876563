<template>
  <div class="home">
    <div class="bodydiv">
      <aside>
        <div class="logotitle" @click="backindex()">
          <!-- <i class="el-icon-cherry"></i> -->
          <img :src="home_logo" alt="" />
          <!-- <span>EasyNoteAI</span> -->
        </div>
        <ul>
          <li :class="{ liactiveis: asidekey == 'home' }" @click="gopageurl('home')">
            <!-- <i class="el-icon-s-home"></i> -->
            <img :src="dashboard" alt="" />
            Dashboard
          </li>
          <li :class="{ liactiveis: asidekey == 'supports' }" @click="gopageurl('supports')">
            <!-- <i class="el-icon-s-home"></i> -->
            <img :src="supports" alt="" />
            Supports
          </li>
          <li @click="JoinDiscord">
            <!-- <i class="el-icon-s-tools"></i> -->
            <img :src="discordlogo" alt="" />
            Join Discord
          </li>
          <li :class="{ liactiveis: asidekey == 'settings' }" @click="gopageurl('settings')">
            <!-- <i class="el-icon-s-tools"></i> -->
            <img :src="settingicon" alt="" />
            Settings
          </li>

        </ul>
        <div class="gopaypage" @click="gopaypage">
          <!-- <i class="el-icon-star-on"></i> -->
          <!-- <img :src="vipicon" alt=""> -->
          ✨ Upgrade to Premium
        </div>
        <div class="acount" @mouseenter="showpaybtn = true" @mouseleave="showpaybtn = false"
          @click="gopageurl('acount')">
          <div class="signin">
            <!-- <i class="el-icon-user-solid"></i> -->
            <img :src="userimg" alt="" />
            <span>{{ email }}</span>
          </div>
        </div>
      </aside>
      <main>
        <HomePage v-if="asidekey == 'home'" @chooseaside="chooseaside"></HomePage>
        <HomeSetting v-if="asidekey == 'settings'"></HomeSetting>
        <HomeSupports v-if="asidekey == 'supports'"></HomeSupports>
      </main>
    </div>
    <popupdeleteaccount v-if="isdeletepopup" />
  </div>
</template>

<script>

import HomePage from "@/views/pages/home_page/HomePage.vue";
import HomeSetting from "@/views/pages/home_page/home_settings.vue";
import HomeSupports from "@/views/pages/home_page/home_supports.vue";
import popupdeleteaccount from "@/views/pages/home_page/popups/popup_deletea.vue";
export default {
  components: {
    HomePage,
    HomeSetting,
    HomeSupports,
    popupdeleteaccount
  },
  name: "HomeIndexpage",
  data() {
    return {
      dashboard: require("../assets/images/home/dashboard.png"),
      supports: require("../assets/images/home/supports.png"),
      settingicon: require("../assets/images/home/setting.png"),
      discordlogo: require("../assets/images/home/discordlogo.png"),
      // vipicon: require("../assets/images/home/vip_gif1.gif"),

      userimg: require("../assets/images/home/userimg.png"),
      asidekey: "home",
      home_logo: require("../assets/images/index/logo.png"),
      showpaybtn: false,
      email: "",

      isdeletepopup: false,
    };
  },
  mounted() {
    // cookies中获取登录信息。

    // console.log("Route params:", this.$route);
    if (this.$route.params && this.$route.params.pagekey) {
      this.chooseaside(this.$route.params.pagekey);
    } else {
      this.chooseaside("home");
    }

    if (!!this.$cookies.get("userlogin") && !!this.$cookies.get("username")) {
      this.getnotelists();
    }

    this.email = this.$cookies.get("email");
  },
  created() {
    // 添加事件监听
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    window.addEventListener('popstate', this.handleBeforeUnload);
  },
  destroyed() {
    // 移除事件监听
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    window.removeEventListener('popstate', this.handleBeforeUnload);
  },
  watch: {
    '$route'(to,) {
      // 检查路由参数的变化
      const pagekey = to.params.pagekey;
      if (pagekey) {
        // 根据 URL 中的 pagekey 展示相应组件
        this.chooseaside(pagekey);
      }
    }
  },
  methods: {
    handleBeforeUnload(event) {
      const ischilddeep = this.$cookies.get("ischilddeep",'');
      if (ischilddeep == 'islive') {
        const message = 'You have unsaved changes. Do you really want to leave?';
        this.$cookies.set('ischilddeep','')
        event.returnValue = message; // 标准
        return message; // 旧版
      }
    },
    async getnotelists() {
      console.log("getnotelists");
    },
    backindex() {
      this.$router.push({ name: "IndexPage" });
    },
    async testapi() {
      // 测试axios
      try {
        console.log(
          "Request URL:",
          this.$axios.defaults.baseURL + "/api/hello"
        ); // 打印URL
        const res = await this.$axios.get("/api/hello");
        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    },
    gopaypage() {
      this.$router.push({
        name: "IndexPage", // 目标路由的名称
        params: { pagekey: "price" }, // 通过 params 传递参数
        // query: { category: "electronics" }, // 通过 query 传递参数
      });
    },
    gopageurl(key) {
      this.$router.push({
        name: "Homeindex_key", // 目标路由的名称
        params: { pagekey: key }, // 通过 params 传递参数
        // query: { category: "electronics" }, // 通过 query 传递参数
      });
    },
    chooseaside(key) {
      if (key == "acount") {
        key = "settings";
        this.showpaybtn = true;
      }
      this.asidekey = key;
    },
    JoinDiscord() {
      window.open("https://discord.gg/ffkFvpVBjt");
    },
  },
  beforeRouteLeave(to, from, next) {
    // 拦截路由返回（后退按钮）时的离开
    const ischilddeep = this.$cookies.get("ischilddeep",''); 
    if (ischilddeep == 'islive') {
      // 提示用户是否保存数据
      const answer = window.confirm('You have unsaved data, are you sure you want to leave?');
      this.$cookies.set('ischilddeep','')
      if (answer) {
        next(); // 用户确认离开，继续路由跳转
      } else {
        next(false); // 用户取消，阻止路由跳转
      }
    } else {
      next(); // 没有修改数据，继续路由跳转
    }
  },
};
</script>

<style lang="less" scoped>
@import "../assets/styles/home.less";
</style>
