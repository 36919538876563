<template>
  <div class="my-account">
    <h1>
      <!-- <i class="el-icon-s-tools"></i>  -->
      My Account
    </h1>
    <div class="info-item">
      <div class="labelbox">
        <span class="label">Full Name</span
        ><span class="description">Your Full Name</span>
      </div>
      <div class="value">{{ username }}</div>
    </div>

    <div class="info-item">
      <div class="labelbox">
        <span class="label">Email</span
        ><span class="description">Your Email</span>
      </div>
      <div class="value">{{ email }}</div>
    </div>

    <div class="info-item">
      <div class="labelbox">
        <span class="label">Current Pricing Plan</span
        ><span class="description">Your Enrolled Plan</span>
      </div>
      <div class="value">{{ minfo }} Plan</div>
    </div>

    <!-- <div class="info-item">
      <div class="labelbox">
        <span class="label">Subscription</span
        ><span class="description">Your subscription type</span>
      </div>
      <div class="value">{{ minfo }}</div>
    </div> -->

    <div class="info-item">
      <div class="labelbox">
        <span class="label">Billing Portal</span>
        <span class="description"
          >Upgrade, cancel, or view your subscription</span
        >
      </div>
      <button @click="openbilling()" class="billing-btn">Billing Portal</button>
    </div>
    <div class="info-item">
      <div class="labelbox">
        <span class="label">Delete Account</span>
        <span class="description"
          >All of your recordings and notes will be deleted.</span
        >
      </div>
      <button @click="deleteaccount()" class="billing-btn deleteacount">Delete Account</button>
    </div>
    <!-- <div class="info-item">
      <div class="labelbox">
        <div class="discordbox">
          <span class="">Join the Discord</span>
          <img :src="discordlogo" alt="" />
        </div>
      </div>
      <button @click="JoinDiscord()" class="billing-btn discord-btn">
        Join us
      </button>
    </div> -->

    <!-- <h2>July Starter Plan Usage</h2>

    <div class="usage-item" v-for="(item, index) in usageItems" :key="index">
      <div class="usage-label">
        <span>{{ item.label }}</span>
        <span class="usage-value">{{ item.current }}/{{ item.total }}</span>
      </div>
      <div class="usage-bar">
        <div
          class="usage-progress"
          :style="{ width: `${item.progress}%` }"
        ></div>
      </div>
    </div> -->
    <div class="loginout">
      <button @click="logout" class="logout-btn">Log out</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      discordlogo: require("../../../assets/images/home/discordlogo.png"),
      usageItems: [
        { label: "Lecture Hours", current: 0, total: 2, progress: 0 },
        { label: "Quiz Questions", current: 2, total: 5, progress: 40 },
        { label: "Chat Messages", current: 1, total: 10, progress: 10 },
        { label: "PDF Uploads", current: 1, total: 1, progress: 100 },
      ],

      username: "<USERNAME>",
      email: "<EMAIL>",
      minfo: "--",
    };
  },
  mounted() {
    this.email = this.$cookies.get("email");
    this.username = this.$cookies.get("username");
    this.getminfo();
  },
  methods: {
    deleteaccount() {
      this.$parent.isdeletepopup = true;
    },
    JoinDiscord() {
      window.open("https://discord.gg/ffkFvpVBjt");
    },
    openbilling() {
      window.open("https://billing.stripe.com/p/login/6oE5nE1xy12Cg2AdQQ");
    },
    async getminfo() {
      try {
        const subid = this.$cookies.get("subid");
        const response = await this.$axios.post("/api/getminfo/", {
          subid: subid,
        });
        const resdata = response.data;
        if (resdata.code == 200) {
          this.minfo = resdata.minfo;
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    logout() {
      this.$cookies.remove("email");
      this.$cookies.remove("subid");
      this.$cookies.remove("userlogin");
      this.$cookies.remove("username");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="less" scoped>
.my-account {
  color: white;
  padding: 2vw 3vw;
  font-family: Arial, sans-serif;
  h1 {
    font-size: 2vw;
    margin-bottom: 3vw;
    i {
      color: #698496;
      font-size: 43px;
    }
  }

  .info-item {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    padding: 12px 3vw;
    margin: 10px 0px;
    font-family: Outfit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .labelbox {
      font-size: 1vw;
      font-weight: 500;
      .label {
        display: flex;
        flex-direction: column;
      }
      .discordbox {
        display: flex;
        align-items: center;
        .discordtext {
          color: #6c5ce7;
        }
        img {
          max-width: 2vw;
          margin-left: 0.5vw;
        }
      }
    }

    .value {
      font-size: 16px;
      margin: 5px 0;
    }

    .description {
      font-size: 14px;
      color: #888;
    }
  }
  .discord-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 1.5vw;
      margin-right: 0.5vw;
    }
  }
  .billing-btn {
    background-color: #6c5ce7;
    color: white;
    border: none;
    padding: 0.5vw 0;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin: 5px 0;
    min-width: 7vw;
  }
  .deleteacount{
    background-color: rgb(228, 98, 98);
  }

  h2 {
    font-size: 32px;
    margin: 20px 0;
  }

  .usage-item {
    margin-bottom: 15px;

    .usage-label {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .usage-bar {
      background-color: #333;
      height: 18px;
      border-radius: 10px;
      overflow: hidden;
    }

    .usage-progress {
      background-color: #6c5ce7;
      height: 100%;
    }

    .usage-value {
      text-align: right;
      font-size: 14px;
      margin-top: 5px;
    }
  }

  .loginout {
    margin-top: 2vw;
    display: flex;
    justify-content: center;
    button {
      cursor: pointer;
      padding: 0.5vw 15vw;
      border: none;
      border-radius: 5px;
      color: #fff;
      font-size: 0.9vw;
      background: rgb(108, 92, 231);
    }
  }
}
</style>
