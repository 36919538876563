<template>
  <div class="language-selector">
    <!-- <p class="tips" v-if="chooeseLanguageName == ''">Please select a language first</p> -->
    <div class="selectorbox">
      <label for="language-select" v-if="label_not">Note Language:</label>
      <div class="custom-select" @click="toggleDropdown" ref="customSelect">
        <div class="viewL">
          <input type="text" :value="selectedLanguageName" readonly placeholder="Select Language" />
          <i v-if="!isOpen" class="el-icon-arrow-down"></i>
          <i v-else class="el-icon-arrow-right"></i>
        </div>

        <ul class="dropdown-menu" v-show="isOpen">
          <li v-for="language in languages" :key="language.code" @click.stop="selectLanguage(language)"
            :class="{ selected: language.code === selectedLanguage }">
            {{
              language.code === "ZH"
                ? "简体中文"
                : language.code === "ZH(T)"
                  ? "繁体中文"
                  : language.name
            }}
            <!-- {{ language.name }} -->
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SelectLanguage",
  props: {
    // languages: Array,
    // selectedLanguage: String,
    chooeseLanguage: String,
    chooeseLanguageName: String,
    label_not: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedLanguage: "",
      selectedLanguageName: "",
      languages: [
        { code: "EN", name: "English" },
        { code: "ZH", name: "Chinese" },
        { code: "ZH(T)", name: "Traditional Chinese (Hong Kong)" },
        { code: "ES", name: "Spanish" },
        { code: "FR", name: "French" },
        { code: "DE", name: "German" },
        { code: "IT", name: "Italian" },
        { code: "JA", name: "Japanese" },
        { code: "RU", name: "Russian" },
        { code: "AR", name: "Arabic" },
        { code: "HI", name: "Hindi" },
        { code: "PT", name: "Portuguese" },
        { code: "NL", name: "Dutch" },
        { code: "KO", name: "Korean" },
        { code: "TR", name: "Turkish" },
      ],
      isOpen: false,
    };
  },
  mounted() {
    this.selectedLanguage = this.chooeseLanguage;
    this.selectedLanguageName = this.chooeseLanguageName;
    if (this.chooeseLanguage === "ZH") {
      this.selectedLanguageName = "简体中文";
    }
    if (this.chooeseLanguage === "ZH(T)") {
      this.selectedLanguageName = "繁体中文";
    }
  },

  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    openDropdown() {
      this.isOpen = true;
    },
    selectLanguage(language) {
      this.selectedLanguage = language.code;
      this.selectedLanguageName = language.name;
      if (language.code === "ZH") {
        this.selectedLanguageName = "简体中文";
      }
      if (language.code === "ZH(T)") {
        this.selectedLanguageName = "繁体中文";
      }
      this.$emit("chooeselanguage", language.code, language.name);
      this.isOpen = false;
    },
  },
};
</script>
<style lang="less">
.language-selector {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-top: 1vw;
  width: 100%;

  .tips {
    color: #fff;
    margin: 0;
    padding: 0;
    margin-bottom: 0.7vw;
    text-align: left;
  }

  .selectorbox {
    width: 100%;
  }

  label {
    color: #fff;
    margin-right: 0.5vw;
    font-size: 1vw;
  }

  .custom-select {
    position: relative;
    padding-top: 0.5vw;
    width: 100%;

    .viewL {
      width: 100%;

      input {
        width: 98%;
        height: 2vw;
        border: 1px solid #ccc;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        position: relative;
      }

      i {
        position: absolute;
        top: 1vw;
        right: 0.5vw;
        color: #000;
        font-size: 1.5vw;
      }
    }

    .dropdown-menu {
      position: absolute;
      top: 105%;
      right: 0;
      width: 99%;
      padding: 0px;
      max-height: 150px;
      overflow-y: auto;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      z-index: 10;
      list-style: none;
      color: #000;

      li {
        padding: 5px;
        cursor: pointer;

        &:hover,
        &.selected {
          background-color: rgb(108, 92, 231);
          color: white;
        }
      }
    }
  }
}
</style>
