import { render, staticRenderFns } from "./popup_record_leave.vue?vue&type=template&id=bc94e942&scoped=true"
import script from "./popup_record_leave.vue?vue&type=script&lang=js"
export * from "./popup_record_leave.vue?vue&type=script&lang=js"
import style0 from "./popup_record_leave.vue?vue&type=style&index=0&id=bc94e942&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc94e942",
  null
  
)

export default component.exports