<template>
  <div class="modal-changetitle" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <h2>Rename note</h2>
        <button class="close-button" @click="closeModal">×</button>
      </div>
      <div class="modal-body">
        <p>
          Make changes to your note title here. Click save when you're done.
        </p>
        <input v-model="noteTitle" :placeholder="placeholder" />
      </div>
      <div class="modal-footer">
        <button @click="saveChanges">Save changes</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      noteTitle: "",
      isVisible: false,
      placeholder:"Enter a new title for your note..."
    };
  },
  methods: {
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.noteTitle = "";
      this.isVisible = false;
    },
    saveChanges() {
      if (this.noteTitle === "") {
        return;
      }
      this.$emit("savenotes", this.noteTitle);
      this.closeModal();
    },
  },
};
</script>

<style lang="less" scoped>
.modal-changetitle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: rgb(33 36 46);
    border-radius: 8px;
    width: 550px;
    padding: 20px;
    padding-bottom: 40px;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
      color: white;
      margin: 0;
      margin-top: 10px;
    }

    .close-button {
      background: none;
      border: none;
      color: white;
      font-size: 24px;
      cursor: pointer;
    }
  }

  .modal-body {
    margin-bottom: 20px;

    p {
      color: #b5bac1;
      margin-bottom: 10px;
    }

    input {
      width: calc(100% - 24px);
      padding: 12px;
      margin: 8px 0px;
      border-radius: 4px;
      border: 1px solid #434b5e;
      background-color: transparent;
      color: white;
    }
  }

  .modal-footer {
    text-align: right;

    button {
      background-color: transparent;
      color: white;
      border: none;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
</style>
