<template>
  <div class="modal-record" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content">
      <!-- <i @click.self="closeModal" class="guanbiicon el-icon-close"></i> -->
      <div class="modal-header">
        <div class="modal-icon">
          <!-- <i class="el-icon-microphone"></i> -->
          <img :src="record_icon" alt="" />
        </div>
        <h2>
          <span v-if="recordtool == 'title'">Record Live Lecture</span>
          <span v-if="recordtool == 'language'">Select Note Language</span>
        </h2>

      </div>
      <div class="recordtitle" v-if="recordtool == 'title'">
        <input placeholder="Please enter a title for the recording" v-model="recordtitle" clearable />
      </div>
      <selectlanguage v-if="recordtool == 'language'" :chooeseLanguage="chooeseLanguage" :label_not=false
        :chooeseLanguageName="chooeseLanguageName" @chooeselanguage="chooeselanguage" />
      <button v-if="recordtool == 'title'" class="record-button" @click="goradiopage()">
        <!-- <i class="el-icon-microphone"></i> -->
        Create new record
      </button>
      <el-button element-loading-spinner="el-icon-loading" :element-loading-text="loadingText"
        element-loading-background="rgba(21, 23, 32, 0.8)"  v-loading="isloading"
        v-if="recordtool == 'language'" class="record-button"
        @click="GenerateNow()">
        <!-- <i class="el-icon-microphone"></i> -->
        Generate Now
      </el-button>

      <!-- <el-upload class="upload-demo" ref="uploadhome" :accept="acceptedFormats" action=""
        :disabled="chooeseLanguageName == ''" :http-request="uploadFile" :show-file-list="false"
        v-loading="taskisloading" element-loading-spinner="el-icon-loading" :element-loading-text="loadingText"
        element-loading-background="rgba(21, 23, 32, 0.8)">
        <button class="record-button" @click.stop="handleUploadClick()">
          <i class="el-icon-upload2"></i>Upload record Max size:{{ maxuploadsize }}M
        </button>
      </el-upload> -->

      <!-- <div class="uploadbox" v-loading="isloading" element-loading-spinner="el-icon-loading"
          element-loading-text="Uploading..." element-loading-background="rgba(21, 23, 32, 0.8)">
          <el-upload :class="{ 'upload-demo': true, }" :accept="acceptedFormats" action=""
            :disabled="chooeseLanguageName == ''" :http-request="uploadFile" :show-file-list="false" drag>
            <div :class="{ 'is-disabled': chooeseLanguageName == '' }">
              <div>
                <i class="el-icon-upload2"></i>
                <span>Drag .mp3 record file here, or click to select</span>
              </div>
              <div class="tishi">{{ "Max size:80M" }}</div>
              <span class="tip"><i class="el-icon-info"></i>Tip: Select language available</span>
            </div>
          </el-upload>
        </div> -->

    </div>
  </div>
</template>

<script>
import selectlanguage from "@/views/pages/home_page/popups/selectlanguage.vue";
export default {
  name: "RecordModal",
  props: {
    recordtool: {
      type: String,
      default: "title",
    },
    loadingText: {
      type: String,
      default: "Uploading...",
    },
  },
  components: {
    selectlanguage,
  },
  data() {
    return {
      record_icon: require("@/assets/images/home/record_icon.png"),
      isVisible: false,
      isloading: false,
      acceptedFormats: ".mp3,.wav,.flac,.aac,.ogg,.alac,.aiff,.m4a,.webm",

      chooeseLanguage: "",
      chooeseLanguageName: "",
      chooeseLanguageNamein: "",

      maxuploadsize: 140,

      recordtitle: "",

      islanguage: false,
      isMouseDownOnModal: false,

    };
  },
  computed: {
  },
  methods: {
    chooeselanguage(language, Languagename) {
      this.chooeseLanguage = language
      this.chooeseLanguageName = Languagename

    },
    handleUploadClick() {
      if (this.chooeseLanguageNamein == "" || !this.chooeseLanguageNamein) {
        this.$message.error("Please select a record language first");
        return
      }
      if (this.chooeseLanguageName == "" || !this.chooeseLanguageName) {
        this.$message.error("Please select a Note language first");
        return;
      }
      this.$refs.uploadhome.$el.querySelector('input[type="file"]').click();
      // 用户已登录，不做任何操作，让 el-upload 组件正常工作
    },
    goradiopage() {

      if (this.recordtitle == "") {
        this.$message.error("Please enter a title for the recording");
        return;
      }
      this.$emit("setrecordtitle", this.recordtitle);
      this.$emit("home_chooseaside", "realradio");
      this.closeModal();
    },
    GenerateNow() {
      if (this.chooeseLanguage == "" || !this.chooeseLanguageName) {
        this.$message.error("Please select a record language first");
        return;
      }
      this.isloading = true;
      this.$emit("chooeselanguage", this.chooeseLanguage, this.chooeseLanguageName);
    },
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    uploadFile({ file }) {

      if (!file) {
        return;
      }
      // 验证文件类型
      const fileType = file.type;
      console.log(fileType);
      // console.log(fileType);
      const supportedTypes = [
        "record/mpeg", // MP3
        "record/wav", // WAV
        "record/flac", // FLAC
        "record/aac", // AAC
        "record/ogg", // OGG
        "record/alac", // ALAC
        "record/aiff", // AIFF
        "record/mp4", // M4A
        "record/x-m4a", //M4A
        "video/webm", // WEBM
      ];

      if (!supportedTypes.includes(fileType)) {
        this.$message.error("Unsupported record format");
        return;
      }
      // 验证文件大小
      const fileSize = file.size;
      if (fileSize > this.maxuploadsize * 1024 * 1024) {
        this.$message.error("File size exceeds 80Mb");
        return;
      }

      this.$parent.taskisloading = true;
      this.$emit("upload_audio", 'record', file, this.recordtitle);
    },
  },
};
</script>

<style lang="less">
.modal-record {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;


  .modal-header {
    text-align: center;
    margin-bottom: 20px;

    .modal-icon {
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2vh;

      img {
        max-width: 3vw;
      }

      i {
        font-size: 40px;
        color: white;
      }
    }

    h2 {
      color: white;
      font-size: 1vw;
    }
  }

  .recordtitle {
    margin-bottom: 15px;

    input {
      width: calc(100% - 24px);
      padding: 12px;
      border-radius: 8px;
      border: 1px solid rgb(44 47 58);
      // background-color: rgb(44 47 58);
      // color: white;
      font-size: 14px;

      &::placeholder {
        color: #9ca3af;
      }
    }
  }

  .upload-demo {
    width: 100%;
    height: 100%;
    color: #fafafa;

    i {
      font-weight: 600;
    }

    .el-upload {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .record-button {
    background-color: #6c5ce7;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 0.6vw;
    width: 100%;
    font-size: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1vw;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }

  .recordbtn {
    background-color: rgb(108, 92, 231);
    margin-top: 2vw;
  }


  .modal-content {
    background-color: rgb(56, 53, 74);
    border-radius: 10px;
    padding: 2vw;
    width: 26vw;
    position: relative;
    // .guanbiicon {
    //   cursor: pointer;
    //   position: absolute;
    //   right: 0.5vw;
    //   top: 0.5vw;
    //   font-size: 1.2vw;
    //   padding: 0.1vw;
    //   font-weight: 600;
    //   color: #D8D8DD;
    //   border: 2px solid #D8D8DD;
    //   border-radius: 5px;
    // }
  }
}
</style>