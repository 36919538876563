<template>
  <div class="modal-upload" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content-upload">
      <div class="modal-header">
        <div class="pdf-icon">
          <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z"
              stroke="#A855F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M13 2V9H20" stroke="#A855F7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <h2>Upload Documents to Generate Study Notes</h2>
        <p>(PDF,PPT,WORD,EXCEL,CSV,TXT | Max Size:30M,Max Page:50)</p>
      </div>
      <selectlanguage :chooeseLanguage="chooeseLanguage" :chooeseLanguageName="chooeseLanguageName"
        @chooeselanguage="chooeselanguage" />
      <el-upload class="upload-demo" ref="uploadhome" :accept="acceptedFormats" action=""
        :disabled="chooeseLanguageName == ''" :http-request="onFileSelected" :show-file-list="false"
        v-loading="taskisloading_u" element-loading-spinner="el-icon-loading" element-loading-text="Uploading..."
        element-loading-background="rgba(21, 23, 32, 0.8)">
        <button class="upload-button" @click.stop="handleUploadClick()">
          <!-- <i class="el-icon-microphone"></i> -->
          <i class="el-icon-upload2"></i>Upload
        </button>
      </el-upload>
      <!-- <div :class="{ 'upload-area': true, 'is-disabled': chooeseLanguageName == '' }" @click="triggerFileInput"
        @dragover.prevent @drop.prevent="onDrop" v-loading="isloading" element-loading-text="Uploading..."
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(21, 23, 32, 0.8)">
        <input :disabled="chooeseLanguageName == ''" :class="{ 'is-disabled': chooeseLanguageName == '' }" type="file"
          ref="fileInput" style="display: none" accept=".pdf" @change="onFileSelected" />
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="17 8 12 3 7 8"></polyline>
            <line x1="12" y1="3" x2="12" y2="15"></line>
          </svg>
          <span>Drag pdf file here, or click to select</span>
          <span>{{ "Max size:30M Max page:100" }}</span>
          <span class="tip"><i class="el-icon-info"></i>Tip: Select language available</span>
        </div>
      </div> -->

    </div>
  </div>
</template>

<script>
import selectlanguage from "@/views/pages/home_page/popups/selectlanguage.vue";
export default {
  components: {
    selectlanguage,
  },
  props: {
    taskisloading_u: {
      type: Boolean,
      default: false,
    },
  },
  name: "PDFUploadModal",
  data() {
    return {
      isVisible: false,
      // isloading: false,

      acceptedFormats: ".pdf,.docx,.txt,.doc,.xlsx,.xls,.pptx,.ppt,.csv",

      chooeseLanguage: "",
      chooeseLanguageName: "",
    };
  },
  methods: {
    chooeselanguage(language, Languagename) {
      (this.chooeseLanguage = language),
        (this.chooeseLanguageName = Languagename),
        this.$emit("chooeselanguage", language, Languagename);
      console.log(this.chooeseLanguage, this.chooeseLanguageName);
    },
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleUploadClick() {
      if (this.chooeseLanguageName == "" || !this.chooeseLanguageName) {
        this.$message.error("Please select a language first");
        return;
      }
      this.$refs.uploadhome.$el.querySelector('input[type="file"]').click();
      // 用户已登录，不做任何操作，让 el-upload 组件正常工作
    },
    onFileSelected({ file }) {
      if (file) {
        // 定义允许的文件类型
        const acceptedFormats = [".pdf", ".txt", ".docx", ".xlsx", ".pptx", ".doc", ".xls", ".ppt", ".csv"];
        const fileExtension = `.${file.name.split('.').pop().toLowerCase()}`;

        // 检查文件类型是否在允许的范围内
        if (!acceptedFormats.includes(fileExtension)) {
          this.$message.error("Please upload a document in the correct format" + acceptedFormats.join(", "));
          this.value = ""; // 清空选择的文件
          return;
        }

        // 检查文件大小
        const fileSizeMB = file.size / (1024 * 1024);
        let maxSizeMB = 30;

        // switch (fileExtension) {
        //   case ".pdf":
        //     maxSizeMB = 30;
        //     break;
        //   case ".txt":
        //     maxSizeMB = 2;
        //     break;
        //   case ".doc":
        //   case ".xls":
        //   case ".xlsx":
        //   case ".docx":
        //   case ".ppt":
        //   case ".pptx":
        //   case ".csv":
        //     maxSizeMB = 5;
        //     break;
        //   default:
        //     maxSizeMB = 30; // 默认大小限制为30MB
        //     break;
        // }

        if (fileSizeMB > maxSizeMB) {
          this.$message.error(`File size exceeds ${maxSizeMB}MB.`);
          this.value = ""; // 清空选择的文件
          return;
        }

        // 处理文件
        this.handleFile(file);
      }
    },
    handleFile(file) {
      // 处理上传的PDF文件
      // console.log("PDF file selected:", file.name);
      this.$parent.taskisloading_u = true;
      this.$emit("upload_pdf", file);
      // 这里可以添加文件上传的逻辑
    },
  },
};
</script>

<style lang="less" scoped>
.modal-upload {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content-upload {
    background-color: rgb(56, 53, 74);
    border-radius: 10px;
    padding: 2vw;
    width: 28vw;
  }



  .modal-header {
    text-align: center;
    margin-bottom: 20px;

    .pdf-icon {
      width: 60px;
      height: 60px;
      margin: 10px auto;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    h2 {
      color: white;
      font-size: 1.2vw;
      margin-bottom: 0px;
    }

    p {
      color: #ccc;
      font-size: 0.8vw;
    }
  }


  .upload-demo {
    width: 100%;
    height: 100%;
    color: #fafafa;

    i {
      font-weight: 600;
    }

    .el-upload {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .upload-button {
    background-color: #6c5ce7;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 0.6vw;
    width: 28vw;
    font-size: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1vw;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
  }


}
</style>
