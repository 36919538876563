<template>
  <div class="about">
    <h1>DeepSeek AI Limited</h1>
    <section>
      <p>
        DeepSeek AI Limited was founded in 2024 by a team of experts from the
        world's leading internet and artificial intelligence companies. We are
        committed to deeply integrating AI technology with higher education and
        research scenarios, helping students and researchers improve efficiency
        in their coursework and academic research.
      </p>
      <p>
        EasyNoteAI is a significant AI product launched by DeepSeek AI Limited
        in 2024, targets students and researchers in universities worldwide. It
        helps them quickly extract valuable information from classes, online
        video lectures, and literature readings using AI capabilities, and
        output structured class notes, lecture summaries, and literature
        overviews.
      </p>
      <p>
        EasyNoteAI supports multiple languages, allowing people from
        universities around the world using different languages to fully enjoy
        the efficiency gains brought by AI technology.
      </p>
      <p>
        DeepSeek AI Limited will continue to delve deep into the AI education
        field, constantly exploring and serving a wide range of education users.
      </p>
    </section>

    <section>
      <p>
        If you encounter any problems, please feel free to send me a message or
        contact me via Email:
        <span @click="copyemail()" class="us_email"
          >easynote_ai@hotmail.com</span
        >
      </p>
    </section>
  </div>
</template>
<script>
export default {
  methods: {
    copyemail() {
      let text = document.querySelector(".us_email").innerText;
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.value = text;
      input.select();
      document.execCommand("Copy");
      input.remove();
      this.$message.success("Email has been copied to your clipboard");
    },
  },
};
</script>
<style lang="less" scoped>
.about {
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #fff;
  h1 {
    font-size: 2vw;
    margin-bottom: 1vw;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 12vw;
    margin-bottom: 2vw;
    background-image: url("../../../assets/images/about_bgimg.jpg");
    background-size: cover; /* 让背景图覆盖整个容器 */
    background-position: center; /* 背景图居中显示 */
    background-repeat: no-repeat; /* 不重复背景图 */
  }

  p {

    margin-bottom: 1vw;
    line-height: 1.6;
    font-size: 1vw;
  }
  .us_email {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
