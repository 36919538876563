<template>
  <div class="chat-bot-container">
    <div class="header">
      <div class="title-container">
        <h1 class="title">Chat Bot</h1>
        <p class="subtitle">Ask questions about your uploaded content!</p>
      </div>
    </div>
    <div class="input-container">
      <input
        type="text"
        class="question-input"
        placeholder="Enter your question about this document..."
        v-model="question"
        @keyup.enter="askQuestion()"
      />
      <button class="ask-button" @click="askQuestion()">
        <i class="el-icon-position"></i>
      </button>
    </div>
    <div class="selectlaug">
      <p class="selecttitle">Output In</p>
      <selectlanguage @chooeselanguage="chooeselanguage" />
    </div>
    <div class="questionbox">
      <div class="chat" v-if="loading">
        <div class="ask">{{ loading_ask }}</div>
        <div
          class="answer_loading"
          v-loading="loading"
          element-loading-text="Loading..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.1)"
        ></div>
      </div>
      <div class="chat" v-for="(item, i) in reversedChats" :key="i">
        <div class="ask">{{ item.ask }}</div>
        <div class="answer">
          {{ item.answer }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectlanguage from "@/views/pages/home_page/popups/selectlanguage.vue";
export default {
  components: {
    selectlanguage,
  },
  props: {
    noteobj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      question: "",
      loading: false,
      loading_ask: "",
      chooeseLanguageName: "English",
      chooeseLanguage: "en",

      chats: [],

      timer: {},
    };
  },
  computed: {
    reversedChats() {
      return [...this.chats].reverse();
    },
  },
  mounted() {
    // console.log(this.noteobj);
    this.chats = this.noteobj.note_chats;
  },

  methods: {
    chooeselanguage(language, Languagename) {
      this.chooeseLanguage = language;
      this.chooeseLanguageName = Languagename;
      // this.$emit("chooeselanguage", language, Languagename);
    },
    async askQuestion() {
      if (this.question.trim() !== "") {
        this.loading = true;
        this.loading_ask = this.question;
        const noteid = this.noteobj.noteid;
        // 假设有一个发送问题的方法 sendQuestion
        const res = await this.$axios.post("/api/sendchatask/", {
          ask: this.question,
          noteid: noteid,
          language: this.chooeseLanguageName,
        });
        const redata = res.data;
        if (redata.code === 200) {
          // 清空输入

          const askid = redata.askid;
          this.loading = true;
          this.loading_ask = this.question;
          this.question = "";
          this.getaskanswer_timer(askid);
        }
        if (redata.code === 201) {
          this.$message({
            message: redata.message,
            type: "error",
            duration: 4000, // 持续时间设置为 4000 毫秒（4秒）
          });
        }
        if (redata.code === 203) {
          this.loading = false;
          this.$message({
            message: redata.messages,
            type: "error",
            duration: 4000, // 持续时间设置为 4000 毫秒（4秒）
          });
        }
        if (redata.code === 303) {
          this.loading = false;
          this.$message({
            message: redata.messages,
            type: "error",
            duration: 4000, // 持续时间设置为 4000 毫秒（4秒）
          });
          this.gopaypage();
        }
      } else {
        this.$message.error("Please enter a question.");
      }
    },
    gopaypage() {
      this.$router.push({
        name: "IndexPage", // 目标路由的名称
        params: { pagekey: "price" }, // 通过 params 传递参数
        // query: { category: "electronics" }, // 通过 query 传递参数
      });
    },
    // 轮询回答信息
    getaskanswer_timer(askid) {
      // 这里添加回答逻辑
      this.timer.getaskanswer = setInterval(() => {
        this.getaskanswer(askid);
      }, 2000);
    },
    async getaskanswer(askid) {
      // 这里添加回答逻辑
      try {
        const res = await this.$axios.post("/api/getchatanswer/", {
          askid: askid,
        });
        const resdata = res.data;
        if (resdata.code == 200) {
          if (resdata.status == 0) {
            return;
          }
          if (resdata.status > 1) {
            clearInterval(this.timer.getaskanswer);
            this.$message.error("No answer yet.");
          }
          this.loading = false;
          const answer = resdata.answer;
          const ask = resdata.ask;
          this.chats.push({ ask: ask, answer: answer });
          this.savemessage();
          clearInterval(this.timer.getaskanswer);
        } else {
          clearInterval(this.timer.getaskanswer);
          this.$message("No answer yet.");
        }
      } catch (error) {
        clearInterval(this.timer.getaskanswer);
        // console.log(error);
      }
    },

    async savemessage() {
      try {
        const res = await this.$axios.post("/api/savemessage/", {
          chats: JSON.stringify(this.chats),
          noteid: this.noteobj.noteid,
        });
        const resdata = res.data;
        if (resdata.code == 200) {
          console.log("sava message success");
        } else {
          console.log("sava message fail");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.chat-bot-container {
  padding: 0 2vw;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: white;
  .icon {
    font-size: 36px;
    margin-right: 15px;
    background-color: #7c3aed;
    border-radius: 12px;
    padding: 8px;
  }

  .title-container {
    .title {
      font-family: Outfit;
      font-size: 2vw;
      margin: 0;
    }

    .subtitle {
      color: #a0aec0;
      margin: 5px 0 0 0;
      font-size: 18px;
    }
  }
}

.input-container {
  display: flex;
  width: 100%;
  margin-left: 50%;
  translate: -50%;
  margin-top: 2vw;
  .question-input {
    flex-grow: 1;
    background-color: #2d3748;
    border: none;
    border-radius: 5px 0 0 5px;
    padding: 12px 15px;
    color: white;
    font-size: 16px;
    &::placeholder {
      color: #a0aec0;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #7c3aed;
    }
  }

  .ask-button {
    border: none;
    border-radius: 0px 5px 5px 0px;
    background: rgb(108, 92, 231);
    padding: 0 3vw;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
    &:hover {
      background-color: #6b46c1;
    }
    i {
      margin: 0 5px;
      font-size: 1.5vw;
    }
  }
}
.selectlaug {
  width: 15vw;
  position: relative;
  .selecttitle{
    margin: 0;
    padding: 0.3vw;
    width: 10vw;
    background-color: rgba(42, 40, 68);
    color: #fff;
    position: absolute;
  }
}
.questionbox {
  color: white;
  padding: 1vw 0px;
  max-height: 58vh;
  overflow-y: auto;
  .chat {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5vw;
    .ask {
      font-size: 0.9vw;
      font-weight: 600;
      margin-bottom: 0.5vw;
    }
    .answer {
      font-size: 0.9vw;
      font-weight: 300;
    }
    .answer_loading {
      width: 12vw;
      height: 10vh;
    }
  }
}
</style>
