<template>
    <div class="home">
        <div class="bodydiv">
            <aside>
                <div class="logotitle" @click="backhome('backhomepage')">
                    <!-- <i class="el-icon-cherry"></i> -->
                    <img :src="home_logo" alt="" />
                    <!-- <span>EasyNoteAI</span> -->
                </div>
                <ul>
                    <li :class="{ liactiveis: noteasidekey == 'Notes' }" @click="chooseaside('Notes')">
                        <!-- <i class="el-icon-edit-outline"></i> -->
                        Notes
                    </li>

                    <li :class="{ liactiveis: noteasidekey == 'Transcript' }" @click="chooseaside('Transcript')">
                        <!-- <i class="el-icon-document"></i> -->
                        Transcript
                    </li>
                </ul>
                <div class="gopaypage" @click="gopaypage">
                    <!-- <i class="el-icon-star-on"></i>  -->
                    Upgrade to Premium
                </div>
                <div class="acount acount_notes">
                    <div class="signin">
                        <!-- <i class="el-icon-user-solid"></i> -->
                        <img :src="userimg" alt="" />
                        <span>{{ email }}</span>
                    </div>
                </div>
            </aside>
            <main class="mainview">
                <notenotes v-bind:noteobj="noteobj" v-if="noteasidekey == 'Notes'" />
                <notechatbot v-bind:noteobj="noteobj" v-if="noteasidekey == 'ChatBot'" />
                <noteflashcards v-bind:noteobj="noteobj" v-if="noteasidekey == 'Flashcards'" />
                <notequiz v-bind:questionsI="questionsI" v-if="noteasidekey == 'Quiz'" />
                <notetranscript v-bind:transcript="transcript" v-if="noteasidekey == 'Transcript'" />
                <notesetting v-bind:noteobj="noteobj" @openchangetitle="openchangetitle" @deletenote="deletenote"
                    v-if="noteasidekey == 'settings'" />
            </main>
        </div>
        <notechangetitle @savenotes="savenotes" ref="pchangetitle" />
    </div>
</template>

<script>
import notenotes from "@/views/pages/components/note_notes.vue";
import notechatbot from "@/views/pages/components/note_chatbot.vue";
import noteflashcards from "@/views/pages/components/note_flashcards.vue";
import notequiz from "@/views/pages/components/note_quiz.vue";
import notetranscript from "@/views/pages/components/note_transcript.vue";
import notesetting from "@/views/pages/components/note_settting.vue";
import notechangetitle from "@/views/pages/components/popups/popup_changetitle.vue";

export default {
    components: {
        notenotes,
        notechatbot,
        noteflashcards,
        notequiz,
        notetranscript,
        notesetting,
        notechangetitle,
    },
    name: "HomeIndexpage",
    data() {
        return {
            noteasidekey: "Notes",
            home_logo: require("../assets/images/index/logo.png"),
            userimg: require("../assets/images/home/userimg.png"),

            showpaybtn: false,
            noteobj: { status: -1 },

            questionsI: [],
            transcript: {},

            timer: null,

            email: "",
        };
    },
    watch: {
        noteasidekey(newVal) {
            // 在 noteasidekey 变化时，将滚动条位置重置到最顶部
            console.log("newVal", newVal);
            this.$nextTick(() => {
                document.querySelector(".mainview").scrollTop = 0;
            });
        },
    },
    mounted() {
        this.chooseaside('Notes');
        if (this.$route.query && this.$route.query.t) {
            // console.log("ccc", this.$route.params.note);
            const token = this.$route.query.t;
            this.getnotestoken(token);
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.getnotestoken(token);
            }, 2000);
        }

        //   this.email = this.$cookies.get("email");
    },
    methods: {
        backhome(isget) {
            this.$router.push({
                name: "Homeindex",
                params: { dontgetmynotes: isget },
            });
        },
        openchangetitle() {
            this.$refs.pchangetitle.openModal();
        },
        gopaypage() {
            this.$router.push({
                name: "IndexPage", // 目标路由的名称
                params: { pagekey: "price" }, // 通过 params 传递参数
                // query: { category: "electronics" }, // 通过 query 传递参数
            });
        },
        async getnotestoken(token, status = 0) {
            if (status == 10) {
                this.noteobj.status = 0;
            }
            try {
                const respne = await this.$axios.post("api/getnotestoken/", {
                    token: token,
                });
                const redata = respne.data;
                if (redata.code == 200) {
                    this.noteobj = redata.noteobj;
                    this.noteobj.status = 1;
                    clearInterval(this.timer);
                }
                if (redata.code == 201) {
                    this.$message({
                        center: true,
                        message: "The note does not exist",
                        type: "warning",
                    });
                    clearInterval(this.timer);
                }
                if (redata.code == 202) {
                    this.noteobj.status = 0;
                }
                if (redata.code == 203) {
                    this.noteobj = redata.noteobj;
                    this.$message({
                        center: true,
                        message: "Note generation failed",
                        type: "error",
                    });
                    this.noteobj.status = 3;
                    clearInterval(this.timer);
                }
            } catch (error) {
                clearInterval(this.timer);
                console.log("error", error);
            }
        },
        savenotes(notetitle) {
            // console.log("修改笔记标题notetitle", notetitle);
            this.$set(this.noteobj, "notename", notetitle);
            // 保存新笔记名称到数据库
            this.$axios.post("api/updatenotename/", {
                noteid: this.noteobj.noteid,
                notename: notetitle,
            });
        },
        deletenote() {
            const noteid = this.noteobj.noteid;
            // console.log("删除笔记", noteid);
            this.$axios.post("api/deletenote/", {
                noteid: noteid,
            });
            this.backhome("get");
        },
        chooseaside(key) {
            this.noteasidekey = key;
            switch (key) {
                case "Notes":
                    break;
                case "ChatBot":
                    break;
                case "Flashcards":
                    break;
                case "Quiz":
                    this.questionsI = this.noteobj.note_quiz;
                    for (let i = 0; i < this.questionsI.length; i++) {
                        // ischooes: false,
                        // chooese: -1,
                        this.$set(this.questionsI[i], "ischooes", false);
                        this.$set(this.questionsI[i], "chooese", -1);
                    }
                    break;
                case "Transcript":
                    if (this.noteobj.filetype == 'record') {
                        this.transcript["value"] = this.noteobj.live_transcript;
                        this.transcript["notetoken"] = this.noteobj.notetoken;
                    } else {
                        this.transcript["value"] = this.noteobj.note_transcript;
                        this.transcript["notetoken"] = this.noteobj.notetoken;
                    }
                    this.transcript["type"] = this.noteobj.filetype;
                    break;
                case "settings":
                    break;
                default:
                    break;
            }
        },
        beforeDestroy() {
            // 组件销毁时清理定时任务
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
    },
};
</script>

<style lang="less" scoped>
@import "../assets/styles/home.less";
</style>