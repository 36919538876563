<template>
  <div class="lawful-interception-guide">
    <div class="progress-container" v-show="notes.iscreating == -1">
      <h1>Loading...</h1>
    </div>
    <div class="progress-container" v-show="notes.iscreating == 0">
      <img class="loadingimg" :src="loading_img" alt="" />
      <h1>We're Creating Your Notes...</h1>
      <p class="progress-text">{{ progress }}% Completed</p>
      <div class="progress-bar">
        <div class="progress" :style="{ width: progress + '%' }"></div>
      </div>
      <p v-if="noteobj.filetype == 'audio'" class="uploadtishi"><i class="el-icon-info"></i>Estimated processing time:
        5-30 minutes based on audio length. Please wait
        patiently. Do not refresh or close the page.</p>
    </div>
    <div class="progress-container" v-show="notes.iscreating == 3">
      <img class="loadingimg" :src="error_img" alt="" />
      <h1 v-if="notes.taskcount <= 1">Note generation failed, please try again later.</h1>
      <h1 v-else>It seems there's a problem with the file. <br>Please try a new one.</h1>
      <div class="notebtnbox">
        <button v-if="notes.taskcount <= 1" class="resert" @click="tryagain">Try again</button>
        <button v-if="noteobj.filetype == 'audio'" class="save" @click="saveaudio">Save audio locally</button>
        <span v-if="noteobj.filetype == 'audio'"> <i class="el-icon-warning"></i>The recording file will be deleted in 24 hours. Please save it to your local device immediately.</span>
      </div>
    </div>
    <div class="progress-container" v-show="notes.iscreating == 4">
      <img class="loadingimg" :src="error_img" alt="" />
      <h1>The recording file has been automatically <br>deleted as it has exceeded the 24-hour saving period.</h1>
    </div>
    <div v-show="notes.iscreating == 1">
      <!-- <el-button @click="downloadNotes" class="el-icon-upload2 export">Export</el-button> -->
      <el-dropdown class="export" @command="handleCommand">
        <span class="el-dropdown-link">
          Copy or Share<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="copymarkdown" icon="el-icon-c-scale-to-original">Copy as
            Markdown</el-dropdown-item>
          <el-dropdown-item command="copytext" icon="el-icon-tickets">Copy as TXT</el-dropdown-item>
          <el-dropdown-item command="downloadaspdf" icon="el-icon-download">Download as
            PDF</el-dropdown-item>
          <el-dropdown-item command="noteshare" icon="el-icon-share">Share my Notes</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div ref="contentToDownload" class="markdown-content" v-if="notes.mk_notes" v-html="renderedNotes"></div>
    </div>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
export default {
  props: {
    noteobj: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    noteobj: {
      handler(newobject) {
        this.iscreating = this.noteobj.status;
        console.log("newobject", newobject);
        this.notes["iscreating"] = newobject.status;
        this.notes["taskcount"] = newobject.task_count;
        // this.notes["iscreating"] = 0;
        this.notes["mk_notes"] = newobject.note_text;
      },
      deep: true, // 添加深度监听
    },
  },
  mounted() {
    this.notes["iscreating"] = this.noteobj.status;
    this.notes["mk_notes"] = this.noteobj.note_text;
    this.notes["type"] = this.noteobj.progress;
    this.notes["taskcount"] = this.noteobj.task_count;
    this.setprogress();
  },
  computed: {
    renderedNotes() {
      if (!this.notes || typeof this.notes.mk_notes !== "string") {
        return ""; // 或者返回一个默认消息，如 "No notes available"
      }
      return this.md.render(this.notes.mk_notes);
    },
  },
  data() {
    return {
      loading_img: require("@/assets/images/noteindex/loading_img1.gif"),
      error_img: require("@/assets/images/noteindex/error_note.png"),

      interfaces: [
        {
          name: "HI1",
          description:
            "Handover interface between the Lawful Interception Gateway (LIG) and the Monitoring Center (MC)",
        },
        {
          name: "HI2",
          description:
            "Handover interface between the Network Element (NE) and the Lawful Interception Gateway (LIG)",
        },
        {
          name: "HI3",
          description:
            "Handover interface between the Monitoring Center (MC) and the Law Enforcement Agency (LEA)",
        },
      ],
      definitions: [
        '"Lawful Interception: the interception of telecommunications traffic by authorized law enforcement agencies or other entities, with the purpose of gathering evidence or preventing harm."',
        '"Handover Interface: an interface that enables the transfer of intercepted telecommunications traffic between different entities in the lawful interception architecture."',
      ],
      md: new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true,
        highlight: function (str, lang) {
          if (lang && hljs.getLanguage(lang)) {
            try {
              return hljs.highlight(lang, str).value;
            } catch (__) {
              console.log("error", str, lang);
            }
          }
          return ""; // 使用默认的转义
        },
      }),
      progress: 0,
      iscreating: -1,
      timer: null,
      timer_quick: null,

      notes: {
        mk_notes: "",
        iscreating: 0,
      },
    };
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case "copymarkdown":
          this.copymarkdown();
          break;
        case "copytext":
          this.copytext();
          break;
        case "downloadaspdf":
          this.downloadaspdf();
          break;
        case "noteshare":
          this.noteshare();
          break;
        default:
          break;
      }
    },
    copymarkdown() {
      console.log("copymarkdown", this.notes);
      const markdownContent = this.notes.mk_notes;
      this.copyToClipboard(markdownContent);
      this.$message({ message: "Copied to clipboard", type: "success" });
    },
    copytext() {
      const textContent = this.htmlToText(this.renderedNotes);
      this.copyToClipboard(textContent);
      this.$message({ message: "Copied to clipboard", type: "success" });
    },
    downloadaspdf() {
      // this.generateA4PaginatedPdf();
      this.printPdf();
    },
    async printPdf() {
      try {
        // 获取打印容器
        const printContainer = this.$refs.contentToDownload;
        if (!printContainer || !printContainer.innerHTML.trim()) {
          throw new Error('No content to print');
        }

        // 创建一个隐藏的 iframe
        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.top = '-9999px';
        iframe.style.left = '-9999px';
        document.body.appendChild(iframe);

        // 将打印容器的内容复制到 iframe 中
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        iframeDocument.open();
        iframeDocument.write(`
      <html>
        <head>
          <title>打印内容</title>
          <style>
            /* 添加你需要的样式 */
            body {
              font-family: Arial, sans-serif;
            }
            .print-container {
              padding: 20px;
            }
            ${this.getAdditionalStyles()} /* 获取额外需要的样式 */
          </style>
        </head>
        <body>
          ${printContainer.innerHTML}
        </body>
      </html>
    `);
        iframeDocument.close();

        // 等待 iframe 加载完成
        await new Promise((resolve) => {
          iframe.onload = resolve;
        });

        // 等待一小段时间，确保所有内容都已渲染
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // 调用浏览器的打印功能
        iframe.contentWindow.print();

        // 移除 iframe
        document.body.removeChild(iframe);
      } catch (error) {
        console.error('PDF打印失败:', error);
        alert('PDF打印失败，请稍后重试或联系支持人员。');
      }
    },

    // 获取额外需要的样式
    getAdditionalStyles() {
      // 这里可以返回任何额外需要的CSS
      return `
    /* 额外的CSS样式 */
    .custom-class {
      color: #333;
    }
  `;
    },
    async generateA4PaginatedPdf() {
      const content = this.$refs.contentToDownload;  // 获取需要下载的内容
      if (!content) {
        console.error('未找到需要下载的内容');
        return;
      }

      // 定义 A4 页面的尺寸（单位：像素，96DPI下）
      const A4_WIDTH = 595;
      const A4_HEIGHT = 842;
      const contentWidth = content.scrollWidth; // 容器的宽度
      const contentHeight = content.scrollHeight; // 容器的高度

      let pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: 'a4',
      });

      // 当前已经渲染的高度
      let renderedHeight = 0;

      while (renderedHeight < contentHeight) {
        // 使用 html2canvas 截取当前页面的内容
        const canvas = await html2canvas(content, {
          width: contentWidth,  // 按照容器的宽度截取
          height: Math.min(A4_HEIGHT, contentHeight - renderedHeight),  // 截取剩余高度，最多 A4 高度
          x: 0,
          y: renderedHeight,  // 从当前已经渲染的高度往下截取
          scrollY: 0,  // 不让页面滚动
        });

        const imgData = canvas.toDataURL('image/jpeg', 1.0);

        // 计算图像在 PDF 中的宽高比例
        const imgWidth = A4_WIDTH;
        const imgHeight = (canvas.height * A4_WIDTH) / canvas.width;

        if (renderedHeight > 0) {
          pdf.addPage();  // 添加新的一页
        }
        pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);  // 添加图片到PDF

        renderedHeight += canvas.height;  // 更新渲染过的高度
      }

      // 下载生成的 PDF 文件
      pdf.save('generated_content.pdf');
    },
    async saveaudio() {
      // console.log("saveaudio", this.noteobj);
      const noteid = this.noteobj.noteid; // 替换为实际的文件名
      try {
        const response = await this.$axios({
          url: `/api/download/${noteid}/`,
          method: 'GET',
          responseType: 'blob',
        });
        const contentType = response.headers['content-type'];
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'download';
        if (contentDisposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        console.log('filename', filename, contentType);
        const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('下载文件时出错:', error);
        this.$message({ message: "File not found", type: "error" });
      }
    },
    tryagain() {
      this.$emit("tryagain");
    },
    noteshare() {
      console.log("noteshare", this.noteobj);
      const shareurl = 'https://www.easynoteai.com/share?t=' + this.noteobj.notetoken;
      this.copyToClipboard(shareurl);
      this.$message({ message: "Copied to clipboard", type: "success" });
    },
    downloadNotes() {
      // console.log("downloadNotes",this.noteobj);
      const textContent = this.htmlToText(this.renderedNotes);
      const blob = new Blob([textContent], { type: 'text/plain;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'notes.txt';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    htmlToText(html) {
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || '';
    },
    copyToClipboard(text) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(text).then(() => {
          console.log('Text copied to clipboard');
        }).catch((err) => {
          console.error('Could not copy text: ', err);
        });
      } else {
        // 使用旧的方法复制到剪贴板
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
          console.log('Text copied to clipboard');
        } catch (err) {
          console.error('Could not copy text: ', err);
        }
        document.body.removeChild(textArea);
      }
    },
    setprogress() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        const randomnums = Math.floor(Math.random() * 4) + 1;
        this.progress += randomnums;

        if (this.iscreating == 1) {
          this.progress = 100;
        }
        if (this.progress >= 99) {
          // 停止
          this.progress = 99;
          clearInterval(this.timer);
        }
      }, 3000);
    },

  },
};
</script>

<style lang="less" scoped>
.lawful-interception-guide {
  color: #ffffff;
  font-family: Arial, sans-serif;

  .export {
    position: absolute;
    right: 2.4vw;
    top: 2.6vw;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    border: none;
    font-size: 1vw;
    padding: 0.4vw 1vw;
    cursor: pointer;
    border-radius: 0.5vw;
    transition: all 0.3s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  .progress-container {
    color: white;
    text-align: center;
    padding-top: 20vh;

    h1 {
      margin: 0;
      font-size: 2vw;
      margin-bottom: 10px;
    }

    .loadingimg {
      width: 6.5vw;
    }

    .uploadtishi {
      padding: 2vw 10%;

      i {
        color: rgb(212, 162, 97);
        margin-right: 0.5vw;
      }
    }

    .notebtnbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 3vw;

      button {
        border: none;
        width: 12vw;
        padding: 0.8vw 0;
        margin-bottom: 1vw;
        border-radius: 5px;
        font-size: 1vw;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          opacity: 0.8;
        }
      }

      .resert {
        background-color: rgb(22 163 74);
        color: #fff;
      }

      .save {
        background-color: #fff
      }

      span {
        color: red;
        font-size: 1vw;
      }

    }

    .progress-bar {
      background-color: #383838;
      border-radius: 10px;
      height: 20px;
      margin: 1vw 2.2vw;
      overflow: hidden;

      .progress {
        background: rgb(108, 92, 231);
        height: 100%;
        transition: width 0.5s ease-in-out;
      }
    }

    .progress-text {
      font-size: 16px;
    }
  }

  .markdown-content {
    font-family: Outfit;
    color: #e1e1e1;
    background: rgb(42, 36, 65);
    page-break-inside: avoid;
    padding: 0 2vw;

    h1 {
      margin: 0 !important;
    }
  }

  .markdown-content h1,
  .markdown-content h2,
  .markdown-content h3,
  .markdown-content h4,
  .markdown-content h5,
  .markdown-content h6 {
    font-weight: 600;
  }

  .markdown-content h1 {
    margin: 0;
    font-size: 2em;
    border-bottom: 1px solid #eaecef;
    padding-bottom: 0.3em;
  }

  .markdown-content h2 {
    font-size: 1.5em;
    border-bottom: 1px solid #eaecef;
    padding-bottom: 0.3em;
  }

  .markdown-content h3 {
    font-size: 1.25em;
  }

  .markdown-content h4 {
    font-size: 1em;
  }

  .markdown-content h5 {
    font-size: 0.875em;
  }

  .markdown-content h6 {
    font-size: 0.85em;
    color: #6a737d;
  }

  .markdown-content p {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .markdown-content a {
    color: #0366d6;
    text-decoration: none;
  }

  .markdown-content a:hover {
    text-decoration: underline;
  }

  .markdown-content code {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(27, 31, 35, 0.05);
    border-radius: 3px;
  }

  .markdown-content pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
  }

  .markdown-content blockquote {
    padding: 0 1em;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;
    margin: 0 0 16px 0;
  }

  .markdown-content ul,
  .markdown-content ol {
    padding-left: 2em;
    margin-top: 0;
    margin-bottom: 16px;
  }

  .markdown-content img {
    max-width: 100%;
    box-sizing: content-box;
  }
}
</style>
