// src/axios.js
import axios from 'axios';

const instance = axios.create({
  // baseURL: 'http://10.8.0.89:20790', // 访问服务器api的地址
  // baseURL: 'http://167.99.4.82:20781', // 访问服务器api的地址
  // baseURL: 'https://easynoteai.online', // 访问服务器api的地址
  baseURL: 'https://www.easynoteai.com', // 访问服务器api的地址
  headers: {
    'Content-Type': 'application/json'
  }
});

export default instance;
