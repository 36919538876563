<!-- SubscriptionComponent.vue -->
<template>
    <div>
      <h2>订阅页面</h2>
      <div v-if="!subscribed">
        <form @submit.prevent="createSubscription">
          <div id="card-element">
            <!-- Stripe Elements 将在这里插入信用卡输入框 -->
          </div>
          <div id="card-errors" role="alert"></div>
          <button type="submit">订阅 {{ planName }} (每月 {{ amount / 100 }} 元)</button>
        </form>
      </div>
      <div v-else>
        <p>您已成功订阅 {{ planName }}！</p>
        <button @click="cancelSubscription">取消订阅</button>
      </div>
    </div>
  </template>
  
  <script>
  import { loadStripe } from '@stripe/stripe-js';
  
  export default {
    data() {
      return {
        stripe: null,
        card: null,
        subscribed: false,
        planId: 'plan_xxx', // 替换为您的实际 Stripe 计划 ID
        planName: '高级会员',
        amount: 9900, // 金额,单位为分（99元）
      };
    },
    async mounted() {
      // 加载 Stripe
      this.stripe = await loadStripe('your_publishable_key');
      
      // 创建 Stripe Elements
      const elements = this.stripe.elements();
      
      // 创建卡片元素并挂载到 DOM
      this.card = elements.create('card');
      this.card.mount('#card-element');
  
      // 监听卡片输入错误
      this.card.on('change', (event) => {
        const displayError = document.getElementById('card-errors');
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = '';
        }
      });
  
      // 检查用户是否已订阅
      this.checkSubscriptionStatus();
    },
    methods: {
      async createSubscription() {
        try {
          // 创建支付方式
          const { paymentMethod, error } = await this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
          });
  
          if (error) {
            console.error(error);
            return;
          }
  
          // 创建订阅
          const response = await fetch('/api/create-subscription/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
              paymentMethodId: paymentMethod.id,
              planId: this.planId
            })
          });
          const data = await response.json();
  
          if (data.status === 'active') {
            this.subscribed = true;
            console.log('订阅成功!');
          } else {
            // 处理需要额外操作的情况，如 3D Secure
            const { subscription, paymentMethodId, clientSecret } = data;
            console.log('订阅失败:', subscription, paymentMethodId);
            const result = await this.stripe.confirmCardPayment(clientSecret);
            if (result.error) {
              console.error(result.error);
            } else {
              this.subscribed = true;
              console.log('订阅成功!');
            }
          }
        } catch (error) {
          console.error('订阅过程中出错:', error);
        }
      },
      async cancelSubscription() {
        try {
          const response = await fetch('/api/cancel-subscription/', {
            method: 'POST',
          });
          const data = await response.json();
          if (data.status === 'canceled') {
            this.subscribed = false;
            console.log('订阅已取消');
          }
        } catch (error) {
          console.error('取消订阅时出错:', error);
        }
      },
      async checkSubscriptionStatus() {
        try {
          const response = await fetch('/api/check-subscription/');
          const data = await response.json();
          this.subscribed = data.status === 'active';
        } catch (error) {
          console.error('检查订阅状态时出错:', error);
        }
      }
    }
  };
  </script>