<template>
  <div class="supports">
    <h1 class="title">
      <!-- <i class="el-icon-s-tools"></i>  -->
      Supports
    </h1>
    <div class="support-email">
      <h3>Support Email</h3>
      <p>
        If you encounter any problems, please feel free to send me a message or
        contact me via Email:
        <span @click="copyemail()">easynote_ai@hotmail.com</span>
      </p>
    </div>
    <div class="contact-us">
      <h3>Contact Us</h3>
      <p>
        Tell us your problems and suggestions, and leave your email. We will
        solve them as soon as possible and reply to you by email if necessary.
      </p>
      <textarea class="message-input" placeholder="Say anything, maybe we can help you." v-model="message"></textarea>
      <button class="send-message-btn" @click="sendmessage">
        Send Message
      </button>
    </div>
    <div class="qa">
      <h3>Q&A</h3>
      <div class="qabox" v-for="(qa, i) in qalist" :key="i">
        <h3 class="q">{{ qa.q }}</h3>
        <p>
          {{ qa.a }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SupportsPage",
  data() {
    return {
      qalist: [
        {
          q: "Audio upload failed. How can I find my audio file? ",
          a: "Both the ’upload page‘ and the ’error page‘ provide a ’Save Audio‘ button. Please note that the audio is only retained for 24 hours and will be deleted afterwards. Please save it promptly.",
        },
        {
          q: "Why am I unable to download my audio file?",
          a: "If the recording is around 2 hours, older PCs or tablets may automatically delete the recording due to insufficient memory and browser protection mechanisms. It's recommended to close unnecessary tabs and programs before recording, and to use Chrome for optimal performance.",
        },
        {
          q: "Is my language supported?",
          a: "We provide services in English, French, Spanish, German, Italian, Russian, Japanese, Arabic, Hindi, Portuguese, Dutch, Korean, Turkish, Simplified Chinese, and Traditional Chinese. We're continually expanding our language offerings.",
        },
        {
          q: "Can I use EasyNoteAI on my mobile phone?",
          a: "While we recommend using a computer for now, our mobile app is in development and will be available soon.",
        },
        {
          q: "I am having issues with YouTube videos?",
          a: "Please note that EasyNoteAI currently doesn't support YouTube Shorts or Live streams. If you're still encountering problems, feel free to reach out to our support team via email or the message board.",
        },
        {
          q: "I am having trouble uploading my audio?",
          a: "Please confirm that your file meets these requirements:\n- File size is less than 80MB\n- File format is MP3, AIFF, WAV, FLAC, AAC, OGG, M4A, or ALAC",
        },
        {
          q: "Why did EasyNoteAI only record part of my audio?",
          a: "As a web-based tool, EasyNoteAI can occasionally experience issues due to network conditions or system factors. We're working on a mobile app that will provide a more reliable and efficient recording experience.",
        },
        {
          q: "What is the Free Plan?",
          a: "Our Free Plan lets you try out all EasyNoteAI features, but with limited usage. Upgrade to a subscription for unlimited access.",
        },
        {
          q: "Get the best value with our annual plans!",
          a: "We offer a variety of subscription plans to meet your needs. Upgrade to an annual plan and save 70% compared to monthly billing.",
        },
        {
          q: "How to pause my subscription?",
          a: "We don't offer a pause feature right now. However, you can cancel your subscription at any time.",
        },
        {
          q: "How to end or cancel subscription",
          a: 'If you subscribed through our web app, go to "Settings" and click on "Billing Portal".',
        },
      ],

      message: "",
    };
  },
  methods: {
    async sendmessage() {
      if (!this.message) {
        this.$message({
          message: "Please input your message",
          type: "error",
        });
        return;
      }
      const subid = this.$cookies.get("subid");
      if (!subid) {
        this.$message({
          message: "Please login first",
          type: "error",
        });
        return;
      }
      const redata = await this.$axios.post("/api/savefeedback/", {
        feedbackcontent: this.message,
        subid: subid,
      });
      const data = redata.data;
      if (data.code == 200) {
        this.$message({
          message: "Thanks for your feedback, we will contact you soon",
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "error",
          duration: 5000, // 显示时间延长至5秒
        });
      }
    },
    copyemail() {
      const emailfeed = "easynote_ai@hotmail.com";
      const input = document.createElement("input");
      input.value = emailfeed;

      // 将 input 元素添加到文档中
      document.body.appendChild(input);

      // 选中 input 中的内容
      input.select();

      // 执行复制命令
      document.execCommand("copy");

      // 移除临时的 input 元素
      document.body.removeChild(input);

      // 提示用户复制成功
      this.$message({
        message: "Mailbox copied to clipboard",
        type: "success",
      });
    },
  },
};
</script>

<style lang="less">
.supports {
  color: #fff;
  padding: 2vw 3vw;

  >div {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    padding: 1vw 2vw;
  }

  h1,
  h3,
  p {
    margin: 0;
  }

  h3 {
    margin-bottom: 0.8vw;
  }

  p {
    margin-bottom: 1vw;
  }

  .title {
    margin-bottom: 2.5vw;
  }

  .support-email {
    margin-bottom: 1vw;

    span {
      cursor: pointer;
      color: #6369db;
    }
  }

  .contact-us {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1vw;

    .message-input {
      width: 50%;
      height: 100px;
      border-radius: 5px;
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
      padding: 10px;
      margin-bottom: 20px;
    }

    .send-message-btn {
      background-color: #6369db;
      color: #fff;
      border: none;
      padding: 0.6vw 1vw;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .qa {
    .q {
      font-weight: bold;
      margin: 0;
      padding: 0;
      margin-bottom: 0.5vw;
      color: #6369db;
    }

    p {
      border-bottom: 1px solid #666;
      padding-bottom: 1vw;
    }
  }
}
</style>
