<template>
  <div class="modal-video" v-if="isVisible" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <div class="youtube-icon">
          <img :src="youtobelogo" alt="YouTube Icon" />
        </div>
        <h2>YouTube Video</h2>
      </div>
      <div class="input-container">
        <input type="text" placeholder="Paste a YouTube link. Max time:4 hours" v-model="youtubeLink" />
      </div>
      <selectlanguage :chooeseLanguage="chooeseLanguage" :chooeseLanguageName="chooeseLanguageName"
        @chooeselanguage="chooeselanguage" />
      <button class="generate-button" @click="generateNotes">
        Generate Notes
      </button>

    </div>
  </div>
</template>

<script>
import selectlanguage from "@/views/pages/home_page/popups/selectlanguage.vue";
export default {
  name: "YouTubeModal",
  components: {
    selectlanguage,
  },
  data() {
    return {
      youtobelogo: require("../../../../assets/images/index/youtubelogo.svg"),
      youtubeLink: "",
      isVisible: false,
      chooeseLanguage: "",
      chooeseLanguageName: "",
    };
  },
  methods: {
    chooeselanguage(language, Languagename) {
      this.chooeseLanguage = language
      this.chooeseLanguageName = Languagename
      this.$emit("chooeselanguage", language, Languagename)
    },
    openModal() {
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    generateNotes() {
      // 这里添加生成笔记的逻辑

      if (!this.checkLink()) {
        this.$message.error("Invalid YouTube URL. Please enter a valid URL from youtube.com or youtu.be.");
        return;
      }
      if (this.chooeseLanguageName == "" || !this.chooeseLanguageName) {
        this.$message.error("Please select a language first.");
        return;
      }
      // console.log("Generating notes for:", this.youtubeLink);
      this.$emit("createnotevideo", this.youtubeLink);
    },
    checkLink() {
      const youtubeUrlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "(www\\.)?" + // www.
        "(youtube\\.com|youtu\\.be)" + // domain name
        "\\/.*" // path
      );
      this.linkValid =
        youtubeUrlPattern.test(this.youtubeLink) && this.youtubeLink !== "";
      return this.linkValid;
    },
  },
};
</script>

<style lang="less" scoped>
.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: rgb(56, 53, 74);
    border-radius: 10px;
    padding: 2vw;
    width: 25vw;
  }

  .modal-header {
    text-align: center;
    margin-bottom: 20px;

    .youtube-icon {
      width: 60px;
      height: 60px;
      margin: 5px auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    h2 {
      color: white;
      font-size: 18px;
      margin: 25px 0px;
    }
  }

  .input-container {
    margin-bottom: 15px;

    input {
      width: calc(100% - 24px);
      padding: 12px;
      border-radius: 8px;
      border: 1px solid rgb(44 47 58);
      // background-color: rgb(44 47 58);
      // color: white;
      font-size: 14px;

      &::placeholder {
        color: #9ca3af;
      }
    }
  }

  .generate-button {
    background-color: rgb(104 35 255);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.6vw;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    margin: 1vw 0;
    transition: background-color 0.3s;

    &:hover {
      background-color: #6d28d9;
    }
  }
}
</style>
