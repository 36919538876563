<template>
    <div class="languagein-selector">
        <!-- <p class="tips" v-if="chooeseLanguageName == ''">Please select a language first</p> -->
        <div class="selectorboxin">
            <label for="languagein-select">Audio Language:</label>
            <div class="customin-select" @click="toggleDropdown" ref="customSelect">
                <div class="viewL">
                    <input type="text" :value="selectedLanguageName" readonly placeholder="Select Language" />
                    <i v-if="!isOpen" class="el-icon-arrow-down"></i>
                    <i v-else class="el-icon-arrow-right"></i>
                </div>

                <ul class="dropdown-menuin" v-show="isOpen">
                    <li v-for="language in languages" :key="language.code" @click.stop="selectLanguage(language)"
                        :class="{ selected: language.code === selectedLanguage }">
                        <!-- // language.code === "zh" ? "中文（普通话）" : language.name -->
                        {{
                            language.code === "zh" ? "中文（普通话）" : language.code === "yue" ? "中文（粤语）" : language.name
                        }}
                        <!-- {{ language.name }} -->
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SelectLanguage",
    props: {
        // languages: Array,
        // selectedLanguage: String,
        chooeseLanguage: String,
        chooeseLanguageName: String,
    },
    data() {
        return {
            selectedLanguage: "",
            selectedLanguageName: "",
            languages: [
                { code: "en", name: "English" },
                { code: "zh", name: "Chinese" },
                { code: "yue", name: "Chinese-yue" },
                { code: "ru", name: "Russian" },
                { code: "ja", name: "Japanese" },
                { code: "ko", name: "Korean" },
                { code: "de", name: "German" },
                { code: "es", name: "Spanish" },
                { code: "fr", name: "French" },
                { code: "it", name: "Italian" },
                { code: "ar", name: "Arabic" },
                { code: "hi", name: "Hindi" },
                { code: "pt", name: "Portuguese" },
                { code: "nl", name: "Dutch" },
                { code: "tr", name: "Turkish" },
                { code: "other", name: "Other" }
            ],
            isOpen: false,
        };
    },
    mounted() {
        this.selectedLanguage = this.chooeseLanguage;
        this.selectedLanguageName = this.chooeseLanguageName;
        if (this.chooeseLanguage === "zh") {
            this.selectedLanguageName = "中文（普通话）";
        }
        if (this.chooeseLanguage === "yue") {
            this.selectedLanguageName = "中文（粤语）";
        }
    },

    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        openDropdown() {
            this.isOpen = true;
        },
        selectLanguage(language) {
            this.selectedLanguage = language.code;
            this.selectedLanguageName = language.name;
            if (language.code === "zh") {
                this.selectedLanguageName = "中文（普通话）";
            }
            if (language.code === "yue") {
                this.selectedLanguageName = "中文（粤语）";
            }
            this.$emit("chooeselanguage", language.code, language.name);
            this.isOpen = false;
        },
    },
};
</script>
<style lang="less">
.languagein-selector {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    margin-top: 1vw;
    width: 100%;

    .tips {
        color: #fff;
        margin: 0;
        padding: 0;
        margin-bottom: 0.7vw;
        text-align: left;
    }

    .selectorboxin {
        width: 100%;
    }

    label {
        color: #fff;
        margin-right: 0.5vw;
        font-size: 1vw;
    }

    .customin-select {
        position: relative;
        padding-top: 0.5vw;
        width: 100%;

        .viewL {
            width: 100%;

            input {
                width: 98%;
                height: 2vw;
                border: 1px solid #ccc;
                border-radius: 4px;
                outline: none;
                cursor: pointer;
                position: relative;
            }

            i {
                position: absolute;
                top: 1vw;
                right: 0.5vw;
                color: #000;
                font-size: 1.5vw;
            }
        }

        .dropdown-menuin {
            position: absolute;
            top: 105%;
            right: 0;
            width: 99%;
            padding: 0px;
            max-height: 150px;
            overflow-y: auto;
            background-color: white;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            z-index: 10;
            list-style: none;
            color: #000;

            li {
                padding: 5px;
                cursor: pointer;

                &:hover,
                &.selected {
                    background-color: rgb(108, 92, 231);
                    color: white;
                }
            }
        }
    }
}
</style>