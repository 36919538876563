<template>
  <div class="terms-of-service">
    <h1>Terms of Service</h1>

    <section>
      <h2>Recording Policy</h2>
      <p>
        EasyNoteAI emphasizes the importance of upholding legal and
        institutional regulations concerning the recording and sharing of
        educational content. We require our users to strictly observe the
        following guidelines:
      </p>
      <section style="padding-left: 1vw">
        <h3>Compliance with School or University Policies</h3>
        <p>
          Users must not record or upload any material that contravenes the
          policies set by their school, college, workplace, university, or
          instructor. It is the responsibility of each user to be fully informed
          about and comply with their institution's rules regarding the
          recording and distribution of educational materials and lectures.
        </p>

        <h3>Adherence to Copyright Law</h3>
        <p>
          Users must refrain from recording or uploading any material that is
          protected by copyright unless they have obtained explicit permission
          from the copyright holder to do so. This includes text, images,
          graphics, and any other form of copyrighted material.
        </p>

        <h3>Respecting Privacy and Sensitive Conversations</h3>
        <p>
          Users are prohibited from recording or sharing any sensitive
          conversations or information that would breach privacy laws and
          regulations in the United States. This includes private discussions,
          confidential communications, or any other exchanges that are legally
          protected against unauthorized recording and dissemination.
        </p>
      </section>
      <p>
        EasyNoteAI does not condone and is not liable for the recording or
        uploading of any content that is prohibited by educational institution
        policies, copyright law, or U.S. privacy laws. Users assume full
        responsibility for ensuring their conduct on the platform is in full
        compliance with these laws and policies.
      </p>

      <p>
        By utilizing our platform, users agree to indemnify EasyNoteAI against
        any legal action taken due to the unauthorized recording or sharing of
        material. We trust our users to respect the intellectual property,
        privacy, and policy restrictions that govern educational content and
        private communications.
      </p>
    </section>

    <section>
      <h2>License to View EasyNoteAI Content</h2>
      <p>
        Subject to your adherence to these Terms of Use, EasyNoteAI grants you a
        limited, personal, non-exclusive, non-commercial, revocable, and
        non-transferable license to access and use the content provided by
        EasyNoteAI ("EasyNoteAI Content"). The use of the Services is strictly
        for your personal, non-commercial educational purposes only.
      </p>

      <p>
        You agree not to access, copy, record, or procure content or information
        from the Services through any automated means, including but not limited
        to scripts, bots, spiders, crawlers, or scrapers, or employ any data
        mining technology or processes to frame, mask, extract data, or
        otherwise obtain materials from the EasyNoteAI Content (except as a
        result of standard search engine or Internet browser usage), unless you
        have been specifically permitted to do so in a written agreement with
        EasyNoteAI.
      </p>

      <p>
        If you purchase a subscription or a license to access any EasyNoteAI
        Content, such subscription or license is for your individual use only
        and should not be shared with others. EasyNoteAI may establish
        reasonable boundaries on your scope of access to EasyNoteAI Content,
        which can include limits on time, the number of materials accessed, or
        the number of devices used to access such Content, to prevent
        unauthorized use or third-party access to the Content.
      </p>
    </section>

    <section>
      <h2>Fulfillment Policy</h2>
      <p>
        At EasyNoteAI, we are committed to providing our customers with clear
        and transparent policies regarding refunds, delivery, returns, and
        cancellations. Our aim is to build trust through fairness and clarity,
        ensuring a seamless experience for all our users.
      </p>
      <section style="padding-left: 1vw">
        <h3>Refund Policy</h3>
        <p>
          We stand behind the quality of our software services. However, we
          understand that issues can arise. Our policy permits refunds only if a
          customer experiences a technical malfunction or issue that we are
          unable to resolve. To request a refund, please contact our support
          team within 30 days of your purchase with detailed information about
          the issue you are experiencing.
        </p>
        <h3>Cancellation Policy</h3>
        <p>
          Subscriptions to our services can be cancelled at any time through the
          billing portal on the settings page of our website. Upon cancellation,
          you will retain access to premium features until the end of your
          current billing period. After this period ends, access to premium
          features will be revoked
        </p>
      </section>
      <h2>EasyNoteAI Content and Copyright Policy</h2>
      <p>
        At EasyNoteAI, we respect the intellectual property rights of all
        individuals, including faculty and educational institutions. Our
        platform provides a collaborative space for academic growth, but it is
        paramount that this space operates within the bounds of legal and
        ethical standards, particularly concerning copyright law. We require all
        users to comply with copyright laws and respect the intellectual
        property rights of content creators.
      </p>
    </section>
    <section>
      <h2>Prohibited Actions</h2>
      <p>The following actions are expressly prohibited on EasyNoteAI:</p>
      <ul>
        <li>
          <strong>Uploading Copyrighted Content without Permission:</strong>
          EasyNoteAI does not condone or encourage the upload, sharing, or
          distribution of copyrighted material without proper authorization.
        </li>
        <li>
          <strong
            >Uploading Faculty-Generated Content without Permission:</strong
          >
          Faculty members hold copyright over their instructional materials.
          Students are not permitted to upload faculty-generated content, such
          as lecture notes, slides, or other educational resources, without
          explicit consent from the copyright holder.
        </li>
        <li>
          <strong>Sharing of Recordings or Transcripts:</strong> EasyNoteAI
          strictly prohibits the sharing of direct lecture recordings or
          verbatim transcripts on our platform. In fact, we do not store lecture
          transcripts nor do we provide users with access to them. This measure
          is in place to respect the intellectual property of educators and the
          privacy of the classroom environment.
        </li>
        <li>
          <strong>Disobeying University Policies:</strong> Users must ensure
          that any content uploaded to their university's community on
          EasyNoteAI complies with all relevant university policies. This
          includes, but is not limited to, policies against unauthorized
          recordings, sharing of materials, and breaches of academic integrity.
          If an action is legal in a user's locality but contravenes university
          regulations, it should not be performed or shared on our platform.
        </li>
      </ul>
      <p>
        The educational content provided to EasyNoteAI users is delivered in the
        form of summarized, concise, and AI-generated overviews to ensure
        effective learning while respecting copyright norms.
      </p>
    </section>

    <section>
      <!-- <h2>Compliance with Copyright Law</h2>
      <p>
        EasyNoteAI complies with the provisions of the Digital Millennium
        Copyright Act (DMCA). Users who wish to report a violation may submit a
        DMCA notice to
        <a href="mailto:admin@EasyNoteAI.ai">admin@easynoteai.ai</a> including:
      </p> -->
      <ul>
        <li>Your contact information.</li>
        <li>
          A description of the copyrighted work claimed to have been infringed.
        </li>
        <li>A description of where the material is located on our platform.</li>
        <li>
          A statement of good faith belief that the disputed use is not
          authorized.
        </li>
        <li>Your electronic or physical signature.</li>
      </ul>
    </section>

    <section>
      <h2>Accountability and Consequences</h2>
      <p>
        Upon receipt of a valid copyright infringement notice, EasyNoteAI will
        act promptly to remove or disable access to the material claimed to be
        infringing.
      </p>
      <p>
        Violation of our policy may result in various consequences, including:
      </p>
      <ul>
        <li>The removal of the infringing content.</li>
        <li>Suspension of the user's access to our services.</li>
        <li>Termination of the user's account.</li>
        <li>Legal action against the user for copyright infringement.</li>
      </ul>
    </section>

    <section>
      <!-- <h3>Collaboration with Academic Institutions</h3>
      <p>
        EasyNoteAI seeks to collaborate with academic institutions to ensure
        the proper use of our platform. If you represent an academic institution
        and have concerns or wish to establish guidelines for your students and
        faculty regarding the use of EasyNoteAI, please contact us at
        <a href="mailto:admin@EasyNoteAI.ai">admin@easynoteai.ai</a>. We are
        committed to working with you to ensure our service enhances the
        educational process while respecting intellectual property rights.
      </p> -->
      <h2>Honor Code</h2>
      <p>
        We firmly believe in the essential role of academic integrity in the
        learning process, and we dedicate ourselves to its preservation.
      </p>
      <p>
        Our platform is constructed to facilitate learning by providing
        innovative tools such as AI-generated notes, flashcards, and quizzes.
        These features are devised only to complement and reinforce one's
        learning experience, not to replace the hard work and understanding that
        come from personal study and reflection.
      </p>
      <p>
        It is strictly against our policies for our services to be utilized for
        any form of academic dishonesty. This includes, and is not limited to,
        the sharing of test content or other assessment materials. Engaging in
        such practices, like soliciting or distributing test answers, or
        plagiarizing content to submit as one's own, is fundamentally at odds
        with the educational values we uphold.
      </p>
      <p>
        The misuse of our platform to facilitate cheating, fraud, or academic
        dishonesty is strictly prohibited. If we identify that our services have
        been employed inappropriately, we will take decisive action to maintain
        the integrity of our platform and our learning community, which may
        include the removal of infringing materials, the deactivation of the
        accounts of users involved in such misconduct, and collaboration with
        academic institutions. We reserve the right to share any publicly posted
        content and the account information of the individual who posted that
        content with universities and academic institutions.
      </p>
      <p>
        As students ourselves, we empathize with the demanding schedules and
        pressure that academia imposes. Yet, we are also aware, through the
        cautionary stories of classmates and colleagues, that the shortcuts
        taken by violating academic integrity codes offer no true advantage.
        Simply copying answers or sharing unelaborated solutions may facilitate
        task completion, but it undermines the very goal of education, which is
        learning and comprehension. This is a stance we firmly maintain and
        uphold within our Services.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "TermsOfService",
};
</script>

<style lang="less" scoped>
.terms-of-service {
  color: #ffffff;
  font-family: Arial, sans-serif;
  margin: 0 auto;

  h1 {
    font-size: 2vw;
    margin-bottom: 30px;
    text-align: center;
  }

  h2 {
    font-size: 1.5vw;
    margin-top: 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid #333;
    padding-bottom: 10px;
  }

  h3 {
    font-size: 1.2vw;
    color: rgb(108, 92, 231);
    margin-top: 30px;
    margin-bottom: 15px;
  }

  p,
  li {
    line-height: 1.6;
    margin-bottom: 15px;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 20px;
  }

  a {
    color: #a855f7;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  section {
    margin-bottom: 40px;
  }
}
</style>
